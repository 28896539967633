<template>
    <svg :width="size+'px'" viewBox="0 0 170.652 248.999">
        <path :fill="color" id="Icon_awesome-microphone" data-name="Icon awesome-microphone" d="M125-94.913a46.543,46.543,0,0,0,46.542-46.543v-77.557a46.542,46.542,0,0,0-45.722-47.349A46.542,46.542,0,0,0,78.476-220.64q-.015.813,0,1.627v77.557A46.543,46.543,0,0,0,125-94.913Zm77.571-77.571h-7.75a7.75,7.75,0,0,0-7.75,7.75h0v23.278a62.129,62.129,0,0,1-62.209,62.048q-3.03,0-6.048-.3a63.992,63.992,0,0,1-55.872-64.5V-164.72a7.75,7.75,0,0,0-7.75-7.75H47.433a7.75,7.75,0,0,0-7.75,7.75h0v19.468a88,88,0,0,0,73.689,88.08V-40.62H86.226a7.75,7.75,0,0,0-7.75,7.75h0v7.75a7.75,7.75,0,0,0,7.75,7.75h77.557a7.75,7.75,0,0,0,7.75-7.75h0v-7.75a7.75,7.75,0,0,0-7.75-7.75H136.637V-56.987a85.408,85.408,0,0,0,73.689-84.469V-164.72a7.75,7.75,0,0,0-7.736-7.764Z" transform="translate(-39.674 266.369)" opacity="0.8" style="mix-blend-mode: normal;isolation: isolate"/>
    </svg>
</template>

<script>
export default {
    name:'iconMicrophone',
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
    }
}
</script>

<style scoped>
</style>

