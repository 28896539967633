<template>
    <div class="row">
        <div class="col-md-12">
            <ul class="unstyled-list d-flex p-0 route-tab mb-3">
                <li><router-link to="/" class="color-main-navy">{{ $t('product.home') }}</router-link></li>
                <li><icon-arrow :size="15" class="mx-2"/></li>
                <li><router-link to="/shop" class="color-main-navy">{{ $t('product.products') }}</router-link></li>
                <li><icon-arrow :size="15" class="mx-2"/></li>
                <li class="text-limited color-blue-600 cursor-pointer hover-main-red" @click.prevent="navigateTo">{{ product.subcategory.sub_category_name | textFormat}}</li>
            </ul>
        </div>
    <div class="col-md-12 col-lg-6 col-xl-5">
        <div style="position: sticky; top: 17%;" class="d-none d-md-block" :style="{'z-index': imageGallery}">
            <div class="product-img position-relative">
                <ribbon v-if="product.special_price" style="z-index: 10;"/>
                <icon-favourite class="position-absolute cursor-pointer" :size="30" color="var(--gray-1001)" :class="[checkInFavorites(product) ? 'fill-light-main-red' : '']" @click.native="addToWishList(product)"/>
                <vue-pic-zoom style="z-index: 9;" :url="selectedPicture" @click.native="showLightBox = true" />
                <div id="picture-zoom" />
                <div class="swiper-pagination custom swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-bullets-dynamic">
                    <span class="swiper-pagination-bullet" :class="[item.picture_url === selectedPicture ? 'swiper-pagination-bullet-active' : 'swiper-pagination-bullet-active-prev']"  tabindex="0" role="button" aria-label="Go to slide 1" 
                    :style="{left:(- 8 * (findIndex % 2 == 0 ? findIndex : findIndex - 1)) + 'px'}" v-for="item in product.imageGallery" :key="item.picture" @click="selectedPicture = item.picture_url">
                    </span>
                </div>
            </div>
            <div class="container-swiper-product-list position-relative">
                <div class="swiper-button-prev d-none d-lg-flex" slot="button-prev" style="left: -3px;">
                    <icon-arrow style="transform: rotate(180deg) !important;" color="var(--gray-1001)"/>
                </div>
                <swiper :options="swiperOption" class="swiper-product-list mt-5" ref="swiperProductList">
                    <swiper-slide v-for="{ picture_url } in product.imageGallery" :key="picture_url" @mouseenter.native="selectedPicture = picture_url">
                        <image-product :source="picture_url" :class="{'active': picture_url === selectedPicture}" class="img-product-details loaded"/>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-next d-none d-lg-flex" slot="button-next" style="right: -9px;">
                    <icon-arrow color="var(--gray-1001)" />
                </div>
            </div>
        </div>
        <swiper :options="swiperOption" class="mt-2 d-block d-md-none">
            <swiper-slide :key="picture_url" v-for="{ picture_url } in product.imageGallery">
                <image-product :source="picture_url" class="loaded img-mobile"/>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div> 
        </swiper>
    </div>
        <div class="col-xl-7 col-lg-6 col-md-12 mt-4 mt-md-0">
            <div style="max-width: 665px;">
                <ul class="route-tab unstyled-list d-flex mb-1 mb-sm-0 p-0 size-14 weight-500">
                    <li class="color-blue-600 cursor-pointer hover-main-red  .text-capitalize" @click.prevent="navigateTo">{{ product.category.category_name.replaceAll(',', ' / ').toLowerCase()}}</li>
                    <li><span class="mx-1 color-blue-600 weight-500">/</span></li>
                    <li class="color-blue-600 cursor-pointer hover-main-red" @click.prevent="navigateTo">{{product.subcategory.sub_category_name | textFormat}}</li>
                    <li><span class="mx-1 color-blue-600 weight-500">/</span></li>
                    <li class="color-blue-600 cursor-pointer hover-main-red" @click.prevent="selectBrand(product.brand)">{{product.brand | textFormat }}</li>
                </ul>
                <h3 class="weight-700">{{ product.description | textFormat}}</h3>
                <div class="d-flex flex-wrap">
                    <div class="actions-tab-alt d-flex align-items-center cursor-pointer">
                        <star-rating :rating="product.avgRating" :starSize="20" readOnly :increment="0.1" :showRating="false"/>
                        <span class="ms-2 ms-lg-3 color-dark-gray-300" @click="goComments">({{ product.reviews.length }}) reviews</span>
                    </div>
                    <div v-if="!product.userHasReview" class="actions-tab d-flex align-items-center cursor-pointer ms-2 ms-sm-4 ms-lg-3 ms-xl-5" @click="goOpenComments">
                        <icon-comment class="me-2" :color="'var(--gray-1001)'"/>
                        <span class="color-dark-gray-300">{{$t('product.leave_your_comment')}}</span>
                    </div>
                    <div class="actions-tab d-flex align-items-center cursor-pointer ms-2 ms-sm-4 ms-lg-0 ms-xl-5" :class="{'ms-4 ms-lg-3': product.userHasReview}" @click="share = true">
                        <icon-share class="me-2" :color="'var(--gray-1001)'" :size="18"/>
                        <span class="color-dark-gray-300">{{$t('product.share')}}</span>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <h4 class="weight-700" v-if="!product.special_price">{{product.price | moneyFormat}}</h4>
                    <div v-else class="d-flex flex-column">
                        <div class="d-flex">
                            <h4 class="me-3 special-price color-dark-gray-200 mb-0">{{product.price | moneyFormat}}</h4>
                            <h4 class="weight-700 color-main-red mb-0">{{product.special_price | moneyFormat}}</h4>
                        </div>
                        <span class="weight-400 size-12">
                            {{ $t("product.valid_until.1") }} 
                            <span class="weight-600">
                                {{product.quantity_required_special_price}} 
                            </span>
                            {{ $t("product.valid_until.2") }}
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-3 flex-wrap">
                    <div v-if="product.color !== 'N/A'" class="d-flex flex-column mb-2 mb-sm-4 flex-sm-row mb-md-3 me-sm-3 me-lg-0 flex-lg-column">
                        <span class="size-16 mb-2 mb-sm-0 mb-lg-3 mb-xl-2 me-sm-3 pb-xl-1">{{$t("product.color")}}: <b class="ms-1 ml-1 color-dark-gray-300 weight-400">{{product.color ? (product.color) : 'N/a' | textFormat}}</b></span>
                        <color-picker :selectedColor="product.color" />
                    </div>
                    <span v-else class="size-16 my-2 my-sm-0 me-3 mr-3">{{$t("product.color")}}: <b class="ms-1 ml-1 color-dark-gray-300 weight-400">{{product.color ? (product.color) : 'N/a' | textFormat}}</b></span>
                    <span class="size-16 my-2 my-sm-0 me-3 mr-3">{{$t('home.policies.delivery')}}: <b class="ms-1 ml-1 color-dark-gray-300 weight-400">{{$t('product.working_days')}}</b></span>
                    <span class="size-16 me-3 mr-3">{{$t('home.policies.guarantee.warranty')}}: <b class="ms-1 ml-1 color-dark-gray-300 weight-400">{{product.garanty}} {{$t('product.days')}}</b></span>
                </div>
                <div class="justify-content mt-3 flex-wrap">
                    <span class="title-15 my-2 my-sm-0 me-3 mr-3">{{$t('product.product_code')}}: <span class="ms-1 color-dark-gray-200">( {{product.codarticulo}} )</span></span>
                    <span v-if="entityValidation && clasifications.activated == true" class="title-15 my-2 my-sm-0 me-3 mr-3">{{$t('product.clasification')}}: <span :style="{ backgroundColor: clasifications.bg_color, color: clasifications.color }" class="badge custom-badge ms-1">{{clasifications.description | textFormat}}</span></span>
                </div>
                <p class="size-15 color-dark-gray-400 weight-400">
                </p>
                <div class="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center mt-3 pt-3">
                    <product-quantity-input class="ms-2 ms-sm-0 mb-lg-3 mb-xl-0 me-2" v-if="getQuantity(product) > 0" :product="product" :productQuantity="getQuantity(product)" @deleteProduct="(data)=>{deleteConfirm(data)}"/>
                    <main-button class="action-btn my-3 my-lg-0 mt-xl-0 mb-xl-0" :class="[getQuantity(product) > 0 ?'mx-2 mx-lg-4 mb-lg-3 mb-xl-0':'mx-2 ms-sm-0 me-sm-2 me-lg-4']" @click="increment(product)" icon title="product.add_cart">
                        <icon-cart color="#fff" class="me-2"/> 
                    </main-button>
                    <second-button title="product.buy_now" class="action-btn mx-2 mx-sm-0" @click="buyNow(product)" icon v-if="!entityValidation" :disabled="product.existence == 0 || isLogged == false">
                        <icon-elect color="var(--main-navy)" :size="14" class="me-2"/>
                    </second-button>
                </div>
            </div>
            <section class="mt-4 pt-2">
                <h6 class="title-16 mb-0">{{$t('product.availability_by_branch')}}:</h6>
                <span class="subtitle-15 color-dark-gray-300 weight-400">
                    {{$t('product.you_are_buying_in')}} 
                    <span class="text-capitalize">{{branchSelected.city_name | textFormat}} - {{branchSelected.description | textFormat}} </span>
                    <a href="#" @click.prevent="selectBranch = true" class="weight-600 d-block d-xl-inline ms-xl-2">{{$t('product.change_branch')}}</a> </span>
                <div class="row mt-8" v-if="entityValidation">
                    <div class="table-wrapper-scroll-y my-branch-scrollbar col-xl-6 col-lg-12 col-md-6">
                        <table class="table table-employee mb-0">
                            <thead>
                                <th></th>
                                <th>{{ $t('account.branch') }}</th>
                                <th>{{$t('account.quantity')}}</th>
                            </thead>
                            <tbody>
                                <tr v-for="branch in filterBranchesAvaible" :key="branch.pos_id">
                                    <td>
                                        <icon-check v-if="parseFloat(branch.existencia) > 0" color="var(--green-600)" :size="17" />
                                        <icon-close v-else :color="'var(--danger-700)'" :size="16" />
                                    </td>
                                    <td  class="text-start" :class="{'weight-600': branch.pos_id == branchSelected.codplace}">
                                        {{branch.description | textFormat}}
                                    </td>
                                    <td>{{99 > parseInt(branch.existencia) ? branch.existencia : '+99'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-md-6">
                        <table class="table table-employee mb-0">
                            <thead>
                                <th>{{$t('product.level')}}</th>
                                <th>{{$t('product.price')}}</th>
                                <th>{{$t('product.quantity')}}</th>
                            </thead>
                            <tbody>
                                <tr v-for="level in orderPrices" :key="level.codnivelprecio">
                                    <td><b>{{level.codnivelprecio}}</b></td>
                                    <td>{{level.precio | moneyFormat}}</td>
                                    <td>{{level.cantidadprecio}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-wrapper-scroll-y my-custom-scrollbar col-xl-6 col-lg-12 col-md-6">
                        <table class="table table-employee mb-0">
                            <thead>
                                <th>{{$t('product.date')}}</th>
                                <th>{{ $t('product.quantity') }}</th>
                                <th>{{ $t('product.price') }}</th>
                            </thead>
                            <tbody>
                                <tr v-for=" soldPrice in reqPrice " :key="soldPrice.id" >
                                    <td>{{soldPrice.creation}}</td>
                                    <td>{{soldPrice.quantity}}</td>
                                    <td>{{soldPrice.price | moneyFormat}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <icon-no-product class="iconStyle" v-if="reqPrice == false" :size="60"/>
                    </div>
                </div>
                <ul class="list-branch unstyled-list p-0 mt-4" v-else>
                    <li v-for="(branch, index) in filterBranchesAvaible" :key="index" class="d-flex cursor-normal" data-toggle="tooltip" data-placement="top" :title="branch.description">
                        <div class="me-2">
                            <icon-check v-if="parseFloat(branch.existencia) > 0" color="var(--green-600)" :size="17" />
                            <icon-close v-else :color="'var(--danger-700)'" :size="16" />
                        </div> 
                        <div v-if="branch.pos_id" class="text-limited text-capitalize">
                            {{branch.city_name | textFormat}} - <b>{{branch.description | textFormat}}</b>  
                        </div>
                        <span v-if="parseFloat(branch.existencia) > 0" class="color-dark-gray-200 weight-600 ms-1">
                            ({{99 > parseInt(branch.existencia) ? branch.existencia : '+99'}})
                        </span>
                    </li>
                </ul>
            </section>
            <section>
                <main-nav-tab class="mb-3" :options="optionsTab" :selectedOption="selectedOption" @select="(data) => {selectedOption = data}" style="max-width: 360px;" :key="optionsTab.length"/>
                    <!-- (fix) See more button depending on the height of the -->
                <div class="animation-slide-up" v-show="selectedOption.value === 'description'">
                    <p class="size-15 color-dark-gray-400"></p>
                    <p class="size-15 color-dark-gray-400"></p>
                </div>
                <div class="animation-slide-up pt-3" v-show="selectedOption.value === 'specs'">
                    <div v-if="product.feacture">
                        <div class="d-flex flex-wrap">
                            <div class="product-perk d-flex mb-3" v-for="(perk, index) of productPerks" :key="index">
                                <div class="perk-icon-container d-flex align-content-center justify-content-center bg-light-gray-501 me-2">
                                    <component :is="perk.img" :size="perk.size" color="var(--dark-gray-200)"/>
                                </div>
                                <div class="perk-description-title d-flex">
                                    <span class="color-main-navy-900 me-2">
                                        {{$t(perk.title)}}
                                    </span>
                                    <span class="color-dark-gray-300">
                                        {{$t(perk.description)}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <h5 class="title-18 my-3 pt-1">{{$t("product.product_details")}}</h5>
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td class="weight-600">Core type</td>
                                    <td class="color-dark-gray-300">Polymer</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">Housing material</td>
                                    <td class="color-dark-gray-300">ABS</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">Micro-USB input</td>
                                    <td class="color-dark-gray-300">DC 5V-2.4A</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">Type-C input</td>
                                    <td class="color-dark-gray-300">DC 5V-2.4A</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">USB output1</td>
                                    <td class="color-dark-gray-300">DC 5V-2.4A</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">Product size</td>
                                    <td class="color-dark-gray-300">709*68*28mmmm</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">Working temperature</td>
                                    <td class="color-dark-gray-300">-10℃--50℃</td>
                                </tr>
                                <tr>
                                    <td class="weight-600">Color</td>
                                    <td class="color-dark-gray-300">White</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <section class="pb-4 position-relative" style="z-index: 2;"> <!-- z-index is applied for the blur effect when showing the reviews\questions doesn't affect this section -->
                <h4 class="color-main-navy weight-600 mb-3">{{$t('product.reviews')}}</h4>
                <div>
                    <h2 class="color-main-navy weight-600 mb-0 ms-3 ps-xxl-3 size-40">
                        {{parseFloat(product.avgRating).toFixed(1)}}
                    </h2>
                    <div class="d-flex align-items-center ps-xxl-3 mb-3 ms-3">
                        <star-rating :rating="product.avgRating" :starSize="20" :readOnly="true" :increment="0.1" :showRating="false"/>
                        <span class="ms-3 color-dark-gray-300" style="text-transform: lowercase;">({{product.reviews.length}}) {{$t('product.reviews')}}</span>
                    </div>
                    <div class="row ps-xxl-3 mt-3 ms-1">
                        <div class="col-md-6">
                            <rating-menu ref="rating" alwaysShow class="p-2 ms-3" :summary="rating" :rating="product.avgRating" />
                        </div>
                        <div class="col-md-1">
                            <hr class="my-auto" style="height: 100%; width: 1px;">
                        </div>
                        <div class="d-flex flex-column justify-content-center align-content-center col-md-5 mt-4 mt-md-0 pt-4 pt-md-0">
                            <h2 class="weight-700">{{recommendationRatio}}%</h2>
                            <p class="size-14 mb-0">{{$t('product.consumers_recommend_it')}}.</p>
                            <p class="size-14 mb-0">{{$t('product.based_on')}} <b>{{product.total_reviews}}</b> {{$t('product.opinions')}}.</p>
                        </div>
                    </div>
                </div>
            </section>
            <scroll-view> <!-- nav & scroll-marker -->
                <template slot-scope="product">
                    <scroll-marker class="mt-0" name="review" key="review" :visible="product.review" />
                </template>
            </scroll-view>
            <product-socials ref="productSocials" :product="product" @reviewsUpdated="updateSummary" @refresh="() => {$emit('refresh'); openComment()}" 
                @showLightBox="(data) => {imageGallery = data}"/>
        </div>
        <select-branch v-if="selectBranch" @closeModal="selectBranch = false" />
        <confirm-delete-product v-if="deleteProduct" @confirmDelete="deleteConfirmed" @closeModal="deleteProduct = false" :loading="loadingDelete" />
        <share v-if="share" @closeModal="share = false" :product="product" />

        <light-box v-if="showLightBox" :active="selectedPicture" :images="product.imageGallery" @closeLightBox="showLightBox = false" /><!-- This is the product gallery -->
    </div>
</template>

<script>
import VuePicZoom from './VuePicZoom.vue'
import { productMixin } from '../helpers/mixin/product-mixin'
import ImageProduct from './Image.vue'
import StarRating from './rating/StarRating.vue'
import ColorPicker from './ColorPicker.vue';
import MainButton from '../../../common/components/buttons/MainButton.vue'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'
import { mapGetters } from "vuex";
import { $scrollview } from 'vue-scrollview'
import MainNavTab from '../../../common/components/tabs/MainNavTab.vue'
import RatingMenu from './rating/RatingMenu.vue'
import ProductQuantityInput from './ProductQuantityInput.vue'
import SelectBranch from '../../branches/components/SelectBranch.vue'
import ConfirmDeleteProduct from './modals/ConfirmDeleteProduct.vue'
import Ribbon from './Ribbon.vue'
import Share from './modals/Share.vue'
import LightBox from './modals/LightBox.vue'
import ProductSocials from './ProductSocials.vue'
import ReportQuestionReview from './modals/ReportQuestionReview.vue'
import { http } from '../../../common/services/web-backend';

import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()
import { cartMixin } from "../../cart/helpers/mixins/cart-mixin";

//icons
import IconArrow from '../../../common/svg/iconArrow.vue'
import IconFavourite from '../../../common/svg/iconFavourite.vue'
import IconComment from '../assets/svg/iconComment.vue'
import IconShare from '../../../common/svg/iconShare.vue'
import IconCart from '../../../common/svg/iconCart.vue'
import IconElect from '../assets/svg/iconElect.vue'
import IconCheck from '../../../common/svg/iconCheck.vue'
import IconClose from '../../../common/svg/iconClose.vue'
import IconBluetooth from "../assets/svg/iconBluetooth.vue"
import IconMicrophone from "../assets/svg/iconMicrophone.vue"
import IconNoProduct from '../assets/svg/iconNoProduct.vue';
import { AlgoliaService } from '../services/algolia-service';

const serv = new AlgoliaService();

export default {
    name: "ProductDetail",
    components: {
        VuePicZoom,
        ImageProduct,
        StarRating,
        ColorPicker,
        MainButton,
        SecondButton,
        MainNavTab,
        RatingMenu,
        ProductQuantityInput,
        SelectBranch,
        ConfirmDeleteProduct,
        Ribbon,
        Share,
        LightBox,
        ProductSocials,
        ReportQuestionReview,
        IconArrow,
        IconFavourite,
        IconComment,
        IconShare,
        IconCart,
        IconElect,
        IconCheck,
        IconClose,
        IconBluetooth,
        IconMicrophone,
        IconNoProduct,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    mixins: [productMixin, cartMixin],
    computed: {
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            branches: "branches/branches",
            isLogged: "auth/isLogged",
            user: "auth/userData",
            employee: 'auth/employee',
            employeeActiveCode: 'auth/employeeActiveCode',
            filters: "products/filters",
            configurations: "common/configurations",
            userData: "auth/userData",
        }),
        findIndex(){
            for (let i = 0; i < this.product.imageGallery.length; i++) {
                if(this.product.imageGallery[i].picture_url === this.selectedPicture) return i;
            }
            return 0;
        },
        recommendationRatio(){
            let total = 0;
            if(this.rating.summary){
                this.rating.summary.map(({value}, index) => {
                    if (index > 1 && index < 5) {
                        total += value
                    }
                });
            }
            return Math.floor((total * 100)/ this.rating.total) || 0;
        },
        orderPrices(){
            const productPrices = this.product.prices;
            if(productPrices){
                let order = productPrices.sort((a, b)=> a.codnivelprecio - b.codnivelprecio)
                return order
            }else{
                return ''
            }
        },
        filterBranchesAvaible(){
            let branchesExistence = []
            this.branches.forEach(b=>{
            const validate = this.product.branchesAvailable.find(e=> e.pos_id == b.codplace);
                if(validate){
                    branchesExistence.push({city_name : b.city_name, description: b.description, 
                        existencia: validate.existencia, pos_id : validate.pos_id})
                }
            })
            return branchesExistence;
        },
        entityValidation(){
            return this.employee?.entity?.activated_employee
        },
    },
    data() {
        return {
            clasifications: {},
            selectedPicture: "",
            swiperOption: {
                slidesPerView: 4,
                slidesPerColumn: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        slidesPerColumn: 1,
                        spaceBetween: 80,
                    },
                    768: {
                        slidesPerView: 1,
                        slidesPerColumn: 1,
                        spaceBetween: 0,
                    },
                    992: {
                        slidesPerView: 3,
                        slidesPerColumn: 1,
                        spaceBetween: 0,
                    },
                    1200: {
                        slidesPerView: 4,
                        slidesPerColumn: 1,
                        spaceBetween: 50,
                    },
                    1400: {
                        slidesPerView: 4,
                        slidesPerColumn: 1,
                        spaceBetween: 0,
                    },
                },
            },
            reqPrice: [],
            optionsTab: [
                { value: 'description', title: 'product.description' },
            ],
            productPerks: [
                {
                    img: 'icon-microphone',
                    title: 'product.perk.microphone',
                    description: 'product.perk.yes',
                    size: 11
                },
                {
                    img: 'icon-bluetooth',
                    title: 'product.perk.bluetooth_connection',
                    description: 'product.perk.no',
                    size: 8.5
                },
            ],
            selectedOption: {value: 'description'},
            selectedProduct: {},
            //modals
            showLightBox: false,
            imageGallery: 9,
            share: false,
            selectBranch: false,
            deleteProduct: false,
            rating: {
                summary: null,
                total: null,
            },
            loadingDelete: false
        }
    },
    methods: {
        checkFeature(){
            if (this.product.feacture) {
                if (this.optionsTab.length == 1) this.optionsTab.push({ value: 'specs', title: 'product.specifications' });
            }else {
                if (this.optionsTab.length == 2) this.optionsTab.pop();
            }
        },
        selectBrand(brand){
            this.filters.brands_selected = [brand]
            this.$store.dispatch('products/selectBrands')
            this.$router.replace({path: '/shop'}).catch(() => {})
        },
        getBranch(code){
            return this.$options.filters.filterBranch(this.branches, parseFloat(code));
        },
        async deleteConfirmed() {
            let product = {
                cod_articulo: this.selectedProduct.codarticulo,
                quantity: 0, 
                note: product.note 
            }
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("cart/removeProduct", product);
            } catch (error) {
                console.log(error);
            } finally{
                this.loadingDelete = false;
                // console.log(JSON.stringify(this.getProducts.length));
                if(this.mobile)
                window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
            }
            this.deleteProduct = false;
        },
        deleteConfirm(product){
            this.selectedProduct = product;
            this.deleteProduct = true;
        },
        async buyNow(product){
            if(this.userData.entity.created_from == "ECOMMERCE" && this.configurations.general.web_page.validate_phone_on_checkout){
                const data = await http.post("users/is-phone-number-validated");
                this.loading = false
                if(!data.data.data.is_phone_number_validated){
                    return this.$store.commit('auth/OPEN_MODAL_NUMBER', true)
                }
            }
            let obj = {
                product: product,
                quantity: 1,
                total_amount: 0,
                cod_articulo: product.codarticulo,
                note: product.note 
            }
            this.$store.commit('cart/CLEAR_CART')
            try {
                this.$store.commit("cart/ADD_PRODUCT", {product: obj, codarticulo: product.codarticulo, quantity: 1, note: product.note})
                this.$store.commit("cart/SET_CART", {total_amount: product.price, total_items_products: 1})

                await this.verificationOrder()
                this.$router.push({ path:  '/checkout'}).catch(() =>{ });
                this.$emit('closeModal');
            } catch (error) {
                console.log(error);

                let errorData = {
                    show: true,
                    title: `Lo sentimos ha ocurrido un error.`,
                    description: error.response.data.message
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
            }
        },
        getTooltip(branch) {
            if (this.getBranch(branch.pos_id)){
                return this.$options.filters.textFormat(this.getBranch(branch.pos_id)['city_name'])+' - '+this.$options.filters.textFormat(this.getBranch(branch.pos_id)['description']) 
            }else {
                return ''
            }
        },
        goComments(){
            this.$refs.productSocials.openReviewsTab();
            $scrollview.scrollToComponent("review", 30)
        },
        openComments(){
            this.$refs.productSocials.openComments();
        },
        goOpenComments() {
            if (this.isPageDisable){
                this.$store.commit("common/SET_DISABLE_MODAL", true)
                return
            }
            if (!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true)
                return;
            }else {
                this.openComments()
                this.goComments();
            }
        },
        navigateTo() {
            const category = { name: this.product.subcategory.sub_category_name, value: this.product.subcategory.sub_category_id }
            this.$store.commit("products/SET_CATEGORY", category);
            this.$router.replace({path: '/shop'}).catch(() => {})
        },
        async updateSummary() {
            try {
                const req = await service.getReviewsSummary(this.product.id);
                const req_total = await service.getReviewsPage(this.product.id);
                this.rating.summary = req.data.data; //getting the total number of reviews
                this.rating.total = req_total.data.totalDocs;
            } catch (e) {
                console.log(e);
            }
        },
        async algoliaView(param){
            try {                
                const response = await serv.algoliaView(param)
                console.log(response);
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        product() {
            this.selectedPicture = this.getImages(this.product.imageGallery)
            this.checkFeature();
            setImmediate(() => { //we need an immediate to load the products again. we will get rid of this when we pass this logic to the store.
                this.$refs.productSocials.getQuestions();
                this.$refs.productSocials.getReviews();
            });
        },
    },
    async created(){
        this.$store.commit('products/SET_SINGLE_VIEW_PRODUCT', this.product.id)
        if(this.configurations?.general?.web_page?.activate_algolia_search){
            const params = {
                product_code: this.product.id
            }
            if(this.isLogged){
                this.algoliaView(params)
            }
        }
        this.clasifications = this.product?.classification || {}

        this.selectedPicture = this.getImages(this.product.imageGallery)
        const reqSold = await service.getLastPriceSold(this.product.id)
        this.reqPrice = reqSold.data
    },
    async mounted() { //In case we clicked the reviews link on the Search
        if (this.$route.params.scroll === 'reviews') this.goComments();
        await this.updateSummary();
        this.checkFeature();
    }
}
</script>
<style scoped>
    .custom-badge {
        color: white;
        padding: 0.5em 1em;
        border-radius: 0.25rem;
    }

    .product-img {
        border: 1px solid var(--gray-50);
        border-radius: 13px;
        width: 500px;
        height: 500px;
    }

    .my-branch-scrollbar{
        position: relative;
        height: 185px;
        overflow: auto;
    }
    .my-custom-scrollbar {
        position: relative;
        height: 100px;
        overflow: auto;
    }
    .table-wrapper-scroll-y {
        display: block;
    }
    .container-swiper-product-list {
        width: 500px;
    }
    .swiper-product-list {
        max-width: 450px;
        padding-bottom: 50px;
    }
    .icon-favourite {
        top: 30px;
        right: 30px;
        z-index: 11;
        transition: var(--transition-1);
    }
    .icon-favourite:hover{
        transform: scale(0.93);
        fill: var(--main-red);
    }
    .img-product-details{
        width: 90px;
        height: 90px;
        margin: auto;
        border: 1px solid var(--light-gray-100);
        border-radius: 10px;
        overflow: hidden;
        opacity: 0.7;
    }
    .active.img-product-details{
        border-bottom: 5px solid var(--main-red);
        opacity: 1;
        transition: var(--transition-1);
    }
    .iconStyle{
        display: block;
        margin: 5px auto 0 auto;
    }
    .special-price{
        text-decoration: line-through;
    }
    .main-button::v-deep, .button-secondary::v-deep {
        border-radius: 20px;
        height: 40px;
        font-weight: 600;
    }
    .button-secondary::v-deep > span > div > svg {
        transition: var(--transition-1) !important;
    }
    .button-secondary::v-deep:hover > span > div > svg {
        fill: var(--main-red);
    }
    .main-button::v-deep > span > div {
        display: flex;
    }
    .list-branch {
        columns: 2;
        font-weight: 300;
    }
    .nav-tabs::v-deep > li {
        font-size: 20px;
    }
    .nav-tabs::v-deep > li > a {
        font-size: 20px;
    }
    .quantity-input-container::v-deep {
        border-radius: 20px;
        max-width: 125px; 
        height: 36px;
    }
    section {
        margin-top: 3rem;
    }
    .actions-tab-alt > span{
        transition: var(--transition-1);
    }
    .actions-tab-alt:hover > span {
        color: var(--blue-600) !important;
    }
    .actions-tab{
        transition: var(--transition-1);
    }
    .actions-tab:hover > span{
        color: var(--main-red) !important;
    }
    .actions-tab > svg{
        transition: var(--transition-1);
    }
    .actions-tab:hover > svg{
        fill: var(--main-red) !important;
    }
    #picture-zoom{
        z-index: 10 !important;
    }
    .swiper-pagination::v-deep .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
    }
    .swiper-pagination::v-deep > .swiper-pagination-bullet{
        background-color: var(--gray-50);
    }
    .swiper-pagination::v-deep > .swiper-pagination-bullet:hover{
        transform: scale(0.8);
        background-color: var(--light-gray-501);
    }
    .swiper-button-prev, .swiper-button-next{
        top: 33%;
    }
    .rating-menu{
        max-width: 280px;
    }
    .img-mobile {
        border: 1px solid var(--gray-50);
        border-radius: 10px;
        overflow: hidden;
    }
    .product-perk:not(:last-child) {
        margin-right: 70px;
    }
    .perk-icon-container{
        border-radius: 3.123rem;
        width: 25px;
    }
    .table-striped {
        border-top: 1px solid #dee2e6;
    }
    .table-striped>tbody> tr:not(:nth-of-type(odd)) > * {
        --bs-table-accent-bg: var(--light-gray-100);
    }
    .table-striped>tbody> tr:nth-of-type(odd) > * {
        --bs-table-accent-bg: white;
    }
    table > tbody > tr > td {
        padding: 5px 15px;
    }
    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: no-drop !important;
        pointer-events: all !important;
    }
    .swiper-button-prev.swiper-button-disabled:hover .icon-arrow::v-deep, .swiper-button-next.swiper-button-disabled:hover .icon-arrow::v-deep {
        fill: var(--gray-100) !important;

    }
    .swiper-button-prev.swiper-button-disabled:hover .icon-arrow::v-deep > g, .swiper-button-next.swiper-button-disabled:hover .icon-arrow::v-deep > g{
        stroke: var(--gray-100) !important;
    }
    .swiper-button-prev:hover .icon-arrow::v-deep, .swiper-button-next:hover .icon-arrow::v-deep {
        fill: var(--main-red) !important;
    }
    .swiper-button-prev:hover .icon-arrow::v-deep > g, .swiper-button-next:hover .icon-arrow::v-deep > g{
        stroke: var(--main-red) !important;
    }
    .icon-arrow::v-deep > g{
        stroke: var(--gray-1001);
    }
    .icon-arrow::v-deep > g > path{
        stroke-width: 10;
    }
    .swiper-pagination.custom > span{
        transition: var(--transition-1);
    }
    .custom {
        width: 54px; 
        bottom: 20px; 
        left: 42%; 
        white-space: nowrap;
    }
    @media (max-width: 1400px) {
        .product-img{
            width: 410px;
            height: 410px;
        }
        .swiper-product-list{
            max-width: 350px;
        }
        .container-swiper-product-list{
            width: 410px;
        }
    }
    @media (max-width: 1200px) {
        .action-btn {
            width: 40%;
        }
        .quantity-input-container::v-deep{
            height: 40px !important;
            width: 40%;
        }
        .quantity-input-container::v-deep div{
            height: 100% !important;
            width: 100%;
        }
    }
    @media (max-width: 992px) {
        .action-btn {
            width: 30%;
        }
        
    }
    @media (max-width: 768px) {
        .action-btn {
            width: 35%;
        }
    }
    @media (max-width: 576px) {
        .action-btn {
            width: 100%;
        }
    }
    @media (min-width: 576px) {    
        table > tbody > tr > td {
            padding: 15px 40px;
        }
    }
    @media (min-width: 1400px) {
        .quantity-input-container::v-deep {
            height: 40px;
        }
        .quantity-input-container::v-deep > div{
            height: 100%;
        }
    }
    @media (min-width: 1200px) {
        .action-btn {
            min-width: 200px;
        }
    }
    .ribbon::v-deep > .ribbon-wrapper {
        top: 35px;
    }
    .ribbon::v-deep > .ribbon-wrapper > .ribbon-front {
        width: 145px;
        height: 30px;
        left: -21px;
        font-size: 18px;
        letter-spacing: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ribbon::v-deep > .ribbon-wrapper > .ribbon-edge-topleft {
        border-width: 5px 19px 0 0;
        left: -20px;
    }
    .table-employee.table::v-deep > tbody > tr > td {
        padding: 3px;
    }
    .table-employee.table::v-deep > :not(:first-child) {
        border-top: 1px solid var(--gray-100);
    }
    .table-employee.table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
    }
</style>
