<template>
    <main-modal :title="title" @closeModal="$emit('closeModal')">
        <span class="d-block size-14 color-dark-main-navy mb-4">
            {{$t('product.report_desc')+' '}}
            <span class="weight-400" style="text-transform: lowercase;">{{$t('product.'+this.report.type)}}.</span>
        </span>
        <form action="" class="d-flex flex-column flex-grow-1 h-100" @submit.prevent="">
            <div class="form-group" style="max-width: 440px;">
                <label for="review" class="title-15">{{$t('product.reason')}}</label>
                <main-select :options="reportOptions" :selectedOption="selectedOption" placeholder="product.report_placeholder" 
                @clickHandler="(data)=>{ selectedOption = data }" summaryRef="select-report"/>
            </div>
            <div class="d-flex flex-column form-group mt-4">
                <label for="review" class="d-flex title-15">
                    {{$t('product.detail_motive')}}
                    <span class="ms-2 color-gray-1001">
                        ({{$t('product.optional')}})
                    </span>
                    <span class="weight-500 ms-auto me-2" :class="[comment.length === 300 ? 'color-dark-main-red':'color-dark-gray-200']">{{comment.length}} / 300</span>
                </label>
                <textarea v-model="comment" class="form-control" style="max-width: 440px;" id="review" rows="5" :placeholder="$t('product.report_textarea_placeholder')" maxlength="300"></textarea>
            </div>
            <div class="d-flex mt-4"> 
                <main-button @click="send" title="home.contact.send" id="send" class="ms-auto" icon :disabled="selectedOption.value == ''">
                    <icon-send class="me-3" :size="15"/>
                </main-button>
            </div>
        </form>
    </main-modal>
</template>

<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import MainModal from "../../../../common/components/modals/MainModal.vue"
import MainSelect from '../../../../common/components/selects/MainSelect.vue'
import IconSend from '../../../../common/svg/iconSend.vue'

import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()

export default {
    name: 'ReportQuestionReview',
    components: {
        MainModal, 
        IconSend,
        MainButton,
        MainSelect
    },
    props: {
        report: {
            type: Object,
        },
    },
    computed: {
        title(){
            return `product.report_${this.report.type}_title`;
        },
    },
    methods: {
        async send(){
            try {
                let req = this.report.type === 'review' ? await service.reportReview(this.report.id, {reason: this.selectedOption.value, comment: this.comment}) : await service.reportQuestion(this.report.id, {reason: this.selectedOption.value, comment: this.comment});
                this.$emit("reported", { type: this.report.type, id: this.report.id });
                this.$emit("closeModal");
                return req;
            } catch (e) {
                console.log(e.response);
                this.$emit('errorInReport', e.response.data.message) //exception = { show: true, type: 'report' }
            }
        }
    },
    data() {
        return {
            comment: '',
            reportOptions: [
                {
                    value: 'spam',
                    title: 'product.spam',
                },
                {
                    value: 'no_topic',
                    title: 'product.no_topic',
                },
                {
                    value: 'hate_speech',
                    title: 'product.hate_speech',
                },
                {
                    value: 'harrasment',
                    title: 'product.harrasment',
                },
                {
                    value: 'other',
                    title: 'product.other',
                },
            ],
            selectedOption:  {
                value: '',
                title: '',
            },
        }
    },
}
</script>

<style scoped>
    textarea.form-control::placeholder {
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-gray-200);
    }
    .modal-main-container::v-deep > .modal-main > .content {
        display: flex;
        flex-direction: column;
        overflow: visible;
        
        min-height: 160px;
    }
    .form-group > textarea {
        border-radius: 10px;
        border-color: var(--gray-100);
        resize: none;
    }
    @media (min-width : 576px) {
        .modal-main-container::v-deep > .modal-main {
            min-width: 450px;
        }
    }
</style>