<template>
    <svg :width="size+'px'" :fill="color" viewBox="0 0 199 178.57">
        <g id="Group_6579" data-name="Group 6579" transform="translate(-0.5 -13.245)">
            <ellipse id="Ellipse_380" data-name="Ellipse 380" cx="99.5" cy="8.158" rx="99.5" ry="8.158" transform="translate(0.5 175.499)" fill="#f0f4f9"/>
            <g id="Group_6554" data-name="Group 6554">
            <path id="Path_2640" data-name="Path 2640" d="M46.278,105.412A10.165,10.165,0,0,1,36.114,95.246V32.363a.022.022,0,0,0-.022-.022H31.677A10.167,10.167,0,0,0,21.51,42.508v68.583a10.166,10.166,0,0,0,10.166,10.165h6.75l-4.641,16.628a1.058,1.058,0,0,0,1.54,1.208l32.008-17.837h55.881A10.166,10.166,0,0,0,133.38,111.09v-5.678h-87.1Z" fill="#c6d4db"/>
            <path id="Path_2641" data-name="Path 2641" d="M168.346,13.245H55.541A10.144,10.144,0,0,0,45.4,23.389V87.112A10.142,10.142,0,0,0,55.541,97.254h77.467l32.008,17.838a1.059,1.059,0,0,0,1.535-1.21L161.91,97.254h6.438A10.143,10.143,0,0,0,178.49,87.11V23.389A10.144,10.144,0,0,0,168.346,13.245ZM114.715,75.491a4.987,4.987,0,1,1,1.483-3.548A4.854,4.854,0,0,1,114.715,75.491Zm9.139-26.163,0,0a10.947,10.947,0,0,1-2.473,3.28l-2.736,2.408a15.693,15.693,0,0,0-2.256,2.451,4.335,4.335,0,0,0-.9,2.589,4.311,4.311,0,0,1-1.274,3.162,4.238,4.238,0,0,1-3.1,1.274,3.985,3.985,0,0,1-3.024-1.344,4.735,4.735,0,0,1-1.233-3.33,8.18,8.18,0,0,1,1.059-4.025,12.493,12.493,0,0,1,2.565-3.233L113.327,50a17.963,17.963,0,0,0,2.341-2.52,4.094,4.094,0,0,0,.932-2.466,3.576,3.576,0,0,0-1.515-2.872,5.556,5.556,0,0,0-3.559-1.25,4.944,4.944,0,0,0-3.67,1.248,4.547,4.547,0,0,0-1.406,2.509,3.021,3.021,0,0,1-1.142,2.088,3.79,3.79,0,0,1-2.578.931,3.249,3.249,0,0,1-2.85-1.329,4.743,4.743,0,0,1-.872-2.752c0-2.083,1.229-4.328,3.656-6.669a12.636,12.636,0,0,1,9.1-3.528A13.774,13.774,0,0,1,121,36.7a10.377,10.377,0,0,1,3.881,8.143A9.811,9.811,0,0,1,123.854,49.328Z" fill="#c6d4db"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name:'iconNoQuestions',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
    }
}
</script>

<style scoped>
</style>

