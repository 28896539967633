<template>
  <div v-if="showMenu" class="rating-menu" id="rating-menu">
    <!-- <h6 class="title-16 mb-1">Calificacion <span class="weight-400 color-dark-gray-200 mt-2 ms-2 mb-0 subtitle-14 text-center">{{ isNaN(allRated.length) ? 0 : allRated.length }} {{ $t("product.customer_ratings") }}</span></h6>
      <div class="header d-flex justify-content-start mb-2">
        <star-rating class="" :starSize="20" :readOnly="true" :rating="parseFloat(rating)" :increment="0.1" :showRating="false"/> &nbsp;
        <span class="color-dark-main-navy weight-700 ms-2">{{ isNaN(rating) ? 0 : rating  }} / 5</span>
      </div> -->
      <div class="body d-flex justify-content-start align-content-start flex-column h-100">
        <table>
          <tr v-for="({rating, value}, index) in summary.summary" :key="index">
            <td class="subtitle-14 d-flex text-center color-dark-gray-200 pe-3">{{ rating }}<svg height="20" width="20" viewBox="-1 3 20 20" class="ms-2" step="10" animate="true"><linearGradient id="pytt1" x1="0" x2="100%" y1="0" y2="0"><stop offset="100%" stop-color="#FEC537"></stop><stop offset="100%" stop-color="#c6d4db"></stop></linearGradient><filter id="zxi58p" height="130%" width="130%" filterUnits="userSpaceOnUse"><feGaussianBlur stdDeviation="0" result="coloredBlur"></feGaussianBlur><feMerge><feMergeNode in="coloredBlur"></feMergeNode><feMergeNode in="SourceGraphic"></feMergeNode></feMerge></filter><polygon points="9.090909090909092,2.846648301193756,3.0303030303030303,20,18.181818181818183,7.878787878787879,0,7.878787878787879,15.15151515151515,20" fill="url(#pytt1)" stroke="" filter="url(#zxi58p)"></polygon><polygon points="9.090909090909092,2.846648301193756,3.0303030303030303,20,18.181818181818183,7.878787878787879,0,7.878787878787879,15.15151515151515,20" fill="url(#pytt1)" stroke="#e88620" stroke-width="0" stroke-linejoin="miter"></polygon><polygon points="9.090909090909092,2.846648301193756,3.0303030303030303,20,18.181818181818183,7.878787878787879,0,7.878787878787879,15.15151515151515,20" fill="url(#pytt1)"></polygon></svg></td>
            <td style="width: 74%">
              <div class="progress">
                <div class="progress-bar" :style="{width: percentage(value)+'%'}" />
              </div>
            </td>
            <td class="text-center subtitle-15 weight-500 ps-2" > ({{value}})</td>
          </tr>
        </table>
        <!-- <template v-if="$route.name !== 'Product'" >
          <hr class="mb-0">
          <div class="text-center pt-2">
            <router-link :to="{ name: 'product-detail', params: { id: codarticulo }}" class="size-14">
              {{ $t("product.show_customers_reviews", {1: "0"}) }}
            </router-link>
          </div>
        </template> -->
      </div>
    </div>
</template>

<script>
export default {
  name: "ratingMenu",
  props: {
    summary: {
      type: Object,
    },
    // allRated: {
    //   type: Array
    // },
    rating: {
      type: Number
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      showMenu: this.alwaysShow
    }
  },
  methods: {
    openMenuRating(){
      this.showMenu = true
    },
    percentage(number){
      const percentage = Math.floor((number * 100)/this.summary.total);
      return isNaN(percentage) ? 0 : percentage;
      // const filterRating = this.summary.total.filter(element => {
      //   return Math.ceil(element.rating) === number;
      // });
      // const avg = (((filterRating.length / this.allRated.length ) * 100));
      // if(isNaN(avg)){
      //   return 0;
      // }
      // return parseFloat((avg).toFixed(0))
    },
    closeMenuRating(){
      this.showMenu = false
    },
    // getTotal(rate){
    //   let sum = 0
    //   for (let i = 0; i < this.producto.reviews.length; i++) {
    //     if(this.producto.reviews[i].rated == rate)
    //     sum += 1;
    //   }
    //   return sum
    // }
  },
}
</script>

<style  scoped>
/* .rating-menu{
    margin-bottom: 0;

    overflow-y: auto;

    z-index: 2;
    background-color: #fff;

    box-shadow: 0 4px 5px 0px rgb(0 0 0 / 12%);
    transition: all 0.1s ease 0s;

    border-radius: 5px;
 } */

.progress{
  height: 12px;
  align-items: center;
  padding: 2px 2px;
  border-radius: 20px 

}
.progress-bar{
  height: 100%; 
  background: var(--blue-600); 
  border-radius: 20px 
}
</style>
