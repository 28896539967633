<template>
    <icon-base :fill="color" :width="size + 'px'" viewBox="0 0 100 92.276">
        <g id="Delete_review_icon" data-name="Delete review icon" transform="translate(-451 -1868.305)">
            <ellipse id="Ellipse_410" data-name="Ellipse 410" cx="50" cy="4.099" rx="50" ry="4.099" transform="translate(451 1952.383)" fill="#f0f4f9"/>
            <path id="Path_2644" data-name="Path 2644" d="M-2833.474,171.7a12.772,12.772,0,0,0-12.761,12.784,12.773,12.773,0,0,0,12.783,12.762,12.773,12.773,0,0,0,12.762-12.782,12.769,12.769,0,0,0-12.774-12.764Zm5.045,18.988a1.138,1.138,0,0,1-.193-.011,1.471,1.471,0,0,1-.708-.376l-4.133-4.132-4.143,4.131a1.32,1.32,0,0,1-.687.376.833.833,0,0,1-.4,0,1.2,1.2,0,0,1-1-1.374,1.339,1.339,0,0,1,.376-.708l4.153-4.132-4.132-4.144a1.354,1.354,0,0,1-.4-.686,1.2,1.2,0,0,1,.332-1.052,1.225,1.225,0,0,1,1.709,0l4.186,4.175,4.186-4.175a1.2,1.2,0,0,1,1.69-.017,1.2,1.2,0,0,1,.339,1.069,1.239,1.239,0,0,1-.376.686l-4.143,4.144,4.143,4.132a1.46,1.46,0,0,1,.4.708c0,.065.01.129.01.193a1.2,1.2,0,0,1-1.208,1.193Z" transform="translate(3369.174 1696.605)" fill="#bc2c35"/>
            <path id="Union_37" data-name="Union 37" d="M-1935.06,18590.238l-16.082-8.963h-28.082a5.107,5.107,0,0,1-5.109-5.109v-2.85h43.772a5.107,5.107,0,0,0,5.1-5.113v-24.611a17.327,17.327,0,0,0,2.6.2,17.271,17.271,0,0,0,4.74-.662v33.041a5.107,5.107,0,0,1-5.109,5.109h-3.387l2.328,8.355a.535.535,0,0,1-.047.406.526.526,0,0,1-.464.27A.535.535,0,0,1-1935.06,18590.238Zm-65.57-12.014a.532.532,0,0,1-.373-.654l2.333-8.355h-3.233a5.093,5.093,0,0,1-5.095-5.1v-32.02a5.1,5.1,0,0,1,5.095-5.094h51.792a17.3,17.3,0,0,0,9.989,15.182v21.938a5.1,5.1,0,0,1-5.095,5.094h-38.929l-16.082,8.963a.506.506,0,0,1-.254.066A.541.541,0,0,1-2000.63,18578.225Zm6.77-20.949v.006a1.6,1.6,0,0,0,1.61,1.6h37.385a1.6,1.6,0,0,0,1.61-1.6,1.611,1.611,0,0,0-1.61-1.615h-37.385A1.609,1.609,0,0,0-1993.86,18557.275Zm0-9.437a1.607,1.607,0,0,0,1.61,1.609h37.385a1.607,1.607,0,0,0,1.61-1.609,1.609,1.609,0,0,0-1.61-1.609h-37.385A1.609,1.609,0,0,0-1993.86,18547.838Zm0-9.439a1.606,1.606,0,0,0,1.61,1.605h37.385a1.609,1.609,0,0,0,1.61-1.605,1.61,1.61,0,0,0-1.61-1.609h-37.385A1.607,1.607,0,0,0-1993.86,18538.4Z" transform="translate(2468.558 -16645.457)" fill="#c6d4db" stroke="rgba(0,0,0,0)" stroke-width="1"/>
        </g>
    </icon-base>
</template>

<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
  components: { iconBase },
    name: 'iconDeleteReview',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
    }
}
</script>

<style scoped>

</style>
