<template>
    <main-confirm :confirm-title="`product.delete_${type}`" :description="`product.delete_${type}_description`" @confirm="$emit('confirm')" @cancel="$emit('closeModal')">
        <icon-delete-review v-if="type == 'review'" :size="86" />
        <icon-delete-question v-else-if="type == 'question'" :size="90" />
        <icon-delete v-else :size="100" />
    </main-confirm>
</template>

<script>
import MainConfirm from '@/common/components/modals/MainConfirm.vue';

import IconDeleteReview from '../../assets/svg/iconDeleteReview.vue';
import IconDeleteQuestion from '../../assets/svg/iconDeleteQuestion.vue';
import IconDelete from '../../../cart/assets/svg/iconDelete.vue';

export default {
    name: "ConfirmDeleteType",
    components: {
        MainConfirm,
        IconDeleteReview,
        IconDelete,
        IconDeleteQuestion
    },
    props: {
        type: {
            type: String,
            default: 'answer'
        }
    }
}
</script>

