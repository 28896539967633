<template>
    <section class="mt-0">
        <avatar :showStar="false" :name="question.customer.first_name" :lastname="question.customer.last_name" :rating="question.rating" :date="question.created_at"
        :customerImg="question.customer.photo_url" :customerVerified="question.customer_verified"/>
        <div style="padding-left: 57px;">
            <h6 class="title-16 mb-2 pb-2 text-limited">{{$i18n.locale == 'es' ? '¿':''}}{{question.question}}?</h6>
            <span class="color-blue-600">
                <icon-question-answer :size="16" class="me-2"/>
                <span class="color-blue-600 size-15">{{$t('product.answers')}} ({{question.answers.length}})</span>
                <a v-if="!checkCustomer && !checkPerson && question.answers.length !== 0" href="#" class="ms-4 size-15 hover-underline" @click.prevent="openCreateAnswer(question.id)">{{ $t("product.to_answer") }}</a>
                <a v-if="checkCustomer" href="#" class="ms-4 size-15" @click.prevent="openEditQuestion(question)">{{ $t("product.edit") }}</a>
                <a v-if="checkCustomer" href="#" class="ms-4 size-15" @click.prevent="$emit('openDeleteQuestion', question.id)">{{ $t("product.delete") }}</a>
            </span>
            <div class="position-relative">
                <template>
                    <div v-if="question.answers.length !== 0" :id="`progress-question-${question.id}`">
                        <div class="progress-question" :style="{height: `${height - 35 }px`}"/>
                        <div v-for="answer in question.answers" class="ps-3 pt-3 ms-2" :key="answer.id+Date.now()">
                            <avatar class="answer-avatar" :showStar="false" :name="answer.customer.first_name" :lastname="answer.customer.last_name" 
                            :customerImg="answer.customer.photo_url" :customerVerified="answer.customer_verified" :rating="answer.rating" :date="answer.created_at"
                            :userAnswer="checkCustomerAnswer(answer.customer_id)" @deleteAnswer="$emit('openDeleteAnswer', answer.id)" @editAnswer="openEditAnswer(answer)" 
                            @reportAnswer="reportAnswer(answer.id)" />
                            <div style="padding-left: 52px;">
                                <transition name="blink" mode="out-in">
                                    <p class="color-dark-gray-400 weight-300" style="word-break: break-all;">
                                        {{answer.answer}}
                                    </p>
                                </transition>
                                <div class="d-flex flex-wrap">
                                    <div class="d-none d-md-flex">
                                        <a v-if="!checkCustomerAnswer(answer.customer_id)" @click.prevent="reportAnswer(answer.id)" href="#" class="size-15 hover-underline">
                                            {{$t('product.report')}}
                                        </a>
                                        <a v-if="checkCustomerAnswer(answer.customer_id)" @click.prevent="openEditAnswer(answer)" href="#" class="pe-3 size-15">
                                            {{$t('product.edit')}}
                                        </a>
                                        <a v-if="checkCustomerAnswer(answer.customer_id)" @click.prevent="$emit('openDeleteAnswer', answer.id)"  href="#" class="size-15">
                                            {{$t('product.delete')}}
                                        </a>
                                    </div>
                                    <div class="d-flex flex-wrap flex-sm-nowrap ms-md-auto">
                                        <span class="color-dark-gray-200 d-none d-md-block d-lg-none d-xl-block mb-1 mb-sm-0 me-md-3 text-end">
                                            {{$t('product.was_it_helpful')}}? 
                                        </span>
                                        <a href="#" class="like d-flex align-items-center me-3" @click.prevent="vote(answer.id, {like: 2})" :class="{'active': checkIsLiked(answer)}">
                                            <icon-like color="var(--gray-1001)" class="me-2" :size="18"/> ({{answer.like.length}})
                                        </a> 
                                        <a href="#" class="like d-flex align-items-center" @click.prevent="vote(answer.id, {dislike: 2})" :class="{'active': checkIsDisliked(answer)}">
                                            <icon-dislike color="var(--gray-1001)" class="me-2" :size="18" style="margin-bottom: -7px;"/>({{answer.dislike.length}})
                                        </a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="ps-4 pt-3">
                            <p class="color-dark-gray-400 weight-300">
                                {{$t('product.no_answers_yet')}}
                            </p>
                            <a href="#" class="ms-2 ps-1 size-15 hover-underline" @click.prevent="openCreateAnswer(question.id)">{{ $t("product.to_answer") }} (0)</a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <create-answer v-if="answer.show" :answer="answer" @reload="$emit('reload')" @closeModal="closeAnswerModal" />
        <form-question v-if="question_.show" :question="question_" @reload="$emit('reload')" @closeModal="closeQuestionModal" />
    </section>
</template>

<script>
import Avatar from "./Avatar.vue"
import CreateAnswer from "@/modules/products/components/CreateAnswer.vue";
import FormQuestion from '../../components/modals/FormQuestion'

import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()

import { mapGetters } from "vuex";

export default {
    name: "Question",
    components: {
        Avatar,
        CreateAnswer,
        FormQuestion,
        IconLike: () => import("../../../../common/svg/iconLike.vue"),
        IconDislike: () => import("../../../../common/svg/iconDislike.vue"),
        IconQuestionAnswer: () => import("../../../../common/svg/iconQuestionAnswer.vue"),
    },
    props: {
        question: {
            type: Object
        },
        getHeight:{
            type: String
        }
    },
    data(){
        return {
            height: 0,
            answer: {
                show: false,
                question: '',
                selected: { answer: '' },
            },
            question_: {
                show: false,
                id: '',
                selected: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            isLogged: "auth/isLogged",
            user:"auth/userData",
        }),
        checkCustomer(){
            if(this.user?.entity?.code) return this.question.customer.code == this.user?.entity?.code
            return false;
        },
        checkPerson() {
            if (this.user?.entity?.code) {
                let check = this.question.answers.filter((answer) => answer.customer_id == this.user?.entity?.code);
                return check[0] ? true : false
            }else {
                return false;
            }
        },
    },
    methods: {
        async vote(code, value){
            try {
                if(!this.isLogged) {
                    this.$store.commit("auth/OPEN_MODAL", true);
                    return;
                }
                await service.voteAnswer(code, value)
                this.$emit("updateQuestion")
            } catch (error) {
                console.log(error);
            }
        },
        checkCustomerAnswer(code){
            if(this.user?.entity?.code){
                return code == this.user?.entity?.code
            }
            return false
        },
        checkIsLiked(answer) {
            if (this.user?.entity?.code) {
                let check = answer.like.filter((like) => like.customer_id == this.user?.entity?.code)
                return check[0] ? true : false
            }else {
                return false;
            }

        },
        checkIsDisliked(answer) {
            if (this.user?.entity?.code) {
                let check = answer.dislike.filter((like) => like.customer_id == this.user?.entity?.code)
                return check[0] ? true : false
            }else {
                return false;
            }
        },
        reportAnswer(id){
            if(!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true);
                return;
            }else {
                this.$emit('reportAnswer', id)
            }
        },
        getHeightFunction(){
            let question = document.getElementById(`progress-question-${this.question.id}`)
            this.height = question?.clientHeight
        },
        openCreateAnswer(id){
            if (this.isPageDisable){
                this.$store.commit("common/SET_DISABLE_MODAL", true)
                return
            }
            if(!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true)
                return
            }
            this.$emit('showLightBox', 8)
            this.answer.id = '';
            this.answer.question = id;
            this.answer.show = true;
        },
        openEditAnswer(answer){
            this.answer.show = true;
            this.answer.id = answer.id;
            this.answer.selected = answer;
            this.answer.question = null;
            
            this.$emit('showLightBox', 8)
        },
        openEditQuestion({id, question}) {
            this.question_.id = id;
            this.question_.show = true;
            this.question_.selected = question;

            this.$emit('showLightBox', 8)
        },
        closeAnswerModal(){
            this.$emit('showLightBox', 9)
            this.answer.show = false;
            this.answer.selected = {answer: ''};
            this.answer.question = null;
        },
        closeQuestionModal() {
            this.$emit('showLightBox', 9)
            this.question_.show = false
        }
    },
    watch: {
        getHeight(val){
            if (val == 'questions') this.getHeightFunction()
        },
        question(){
            setTimeout(() => {
                this.getHeightFunction()
            }, 0);
        }
    },
}
</script>

<style scoped>
    .progress-question {
        position: absolute;
        top: 16px;
        
        width: 7px;
        /*height: 80%;*/
        
        background-color: var(--gray-50);
        border-radius: 2px;
    }
    .like {
        color: var(--dark-gray-200) !important;
        min-width: 45px;
    }
    .like > svg {
        transition: var(--transition-1);
    }
    .like:hover > svg {
        fill: var(--blue-600);
    }
    .like:hover { 
        color: var(--blue-600) !important;
    }
    .avatar-container::v-deep > div > div > span {
        flex-wrap: nowrap !important;
    }
    .like.active > svg {
        fill: var(--blue-600);
    }
    .like.active {
        color: var(--blue-600) !important;
    }
    .question-enter-active, .question-leave-active {
        transition: all .15s ease-out;
        opacity: 0;
    }
    .blink-enter-active {
        transition: opacity .5s ease-out;
    }
    .blink-enter, .blink-leave-to {
        opacity: 0;
    }
    .answer-avatar::v-deep .name-content {
        max-width: 190px;
    }
    .answer-avatar::v-deep .name-verified-wrapper {
        /* flex-direction: column; */
        align-items: start !important;
    }
    /* date-swap */
    .answer-avatar::v-deep .name-verified-wrapper :is(.answer-date, .answer-options) {
        display: flex;
        font-size: 13px !important;
    }
    .answer-avatar::v-deep time.date {
        display: none !important;
    }
    /* avatar img */
    .answer-avatar::v-deep .avatar {
        width: 41px;
        min-width: 41px;
        height: 41px;

    }
    .answer-avatar::v-deep .avatar abbr {
        font-size: 14px !important;
    }
    @media (min-width : 576px) {
        /* date-swap */
        .answer-avatar::v-deep .name-content {
            max-width: 226px;
        }
        .answer-avatar::v-deep time.date {
            display: block !important;
        }
        .answer-avatar::v-deep .name-verified-wrapper :is(.answer-date, .answer-options) {
            display: none;
            font-size: 14px !important;
        }
    }
    @media (min-width : 768px) {
        .answer-avatar::v-deep .name-content {
            max-width: 280px;
        }
    }
    @media (min-width : 992px) {
        .answer-avatar::v-deep .name-content {
            max-width: 188px;
        }
    }
    @media (min-width: 1200px) {
        .answer-avatar::v-deep .name-content {
            max-width: 310px;
        }
    }
</style>
