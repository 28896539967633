<template>
    <icon-base :width="size + 'px'" :fill="color" :viewBox="'0 0 200 200'">
        <path d="M0.5,77c0,42.3,34.3,76.5,76.5,76.5c16.3,0,31.5-5.1,43.9-13.8l55.9,55.9c5.2,5.2,13.6,5.2,18.8,0  c2.6-2.6,3.9-6,3.9-9.4s-1.3-6.8-3.9-9.4l-55.9-55.9c8.7-12.4,13.8-27.6,13.8-43.9c0-42.3-34.3-76.5-76.5-76.5S0.5,34.8,0.5,77z   M134.1,77c0,31.5-25.6,57.1-57.1,57.1S19.9,108.6,19.9,77S45.5,19.9,77,19.9S134.1,45.5,134.1,77z"/>
    </icon-base>
</template>
<script>
// used inside the main-confirm modal we need the id whe the user clicks on the path of the svg icon we activate the action, we need to assign that only to the main-modal
export default {
name: 'iconSearch',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
