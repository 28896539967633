<template>
    <div>
        <avatar :name="review.customer.first_name" :lastname="review.customer.last_name" :rating="review.rating" :date="review.created_at"
        :customerImg="review.customer.photo_url" :customerVerified="review.customer.customer_verified" />
        <div class="mt-3" style="padding-left: 57px;">
            <transition-group name="blink" mode="out-in">
                <h6 :key="review.title+'titled'" class="title-16 mb-0">{{review.title}}</h6>
                <p :key="review.comment+'desc'" class="color-dark-gray-400 weight-300" style="word-break: break-word;">{{review.comment}}</p>
            </transition-group>
            <div v-if="review.images.length">
                <transition-group class="d-flex mb-4 flex-wrap" name="blink" mode="out-in">
                    <div v-for="(img, index) in review.images.slice(0, previewImgs)" :key="img" class="container-img position-relative me-3 mb-3" @click="openLightBox(img)">
                        <image-review :source="img" class="position-relative" />
                        <div v-if="index == (previewImgs - 1) && review.images.length > previewImgs" class="show-rest-img position-absolute d-flex align-content-center align-items-center justify-content-center">
                            <span class="size-26 color-white">+{{review.images.length - review.images.slice(0, previewImgs).length}}</span>
                        </div>
                    </div>
                </transition-group>
            </div>
            <div class="d-flex flex-wrap">
                <div class="d-flex mb-0 justify-content-end justify-content-md-start">
                    <div v-if="!checkCustomer" :class="checkCustomer ? 'me-3 me-sm-4':'me-0'" class="text-sm-start">
                        <template v-if="review.userHasReport">
                            <span class="color-dark-gray-200" style="cursor: default;" data-toggle="tooltip" data-placement="top" :title="$t('product.review_reported')">
                                {{$t('product.reported')}}
                                <icon-check-circle class="reported-icon mx-1" :size="15" />
                            </span>
                        </template>
                        <template v-else>
                            <span class="color-blue-600 hover-underline hover-main-red cursor-pointer" @click="reportReview(review._id)" >
                                {{$t('product.report')}}
                            </span>
                        </template>
                    </div>
                    <a v-if="checkCustomer" href="#" @click.prevent="$emit('deleteReview', review._id)" class="text-end text-sm-start me-3 me-sm-4 hover-underline">{{$t('product.delete')}}</a>
                    <a v-if="checkCustomer" href="#" @click.prevent="$emit('edit', review)" class="text-end text-sm-start me-0 hover-underline">{{$t('product.edit')}}</a>
                </div>
                <div class="d-flex align-items-center color-dark-gray-200 ms-auto">
                    <span class="d-none d-sm-flex color-dark-gray-200">{{$t('product.was_it_helpful')}}?</span>
                    <a href="#" class="like ms-3" @click.prevent="vote({like: 2})" :class="{'active': checkIsLiked}">
                        <icon-like class="me-2" color="var(--gray-1001)" :size="18" /> ({{review.like.length}})
                    </a> 
                    <a href="#" class="like ms-3" @click.prevent="vote({dislike: 2})" :class="{'active': checkIsDisliked}">
                        <icon-like class="me-2" color="var(--gray-1001)" :size="18" style="transform: rotate(180deg) translateY(-2px);" /> ({{review.dislike.length}})
                    </a> 
                </div>
            </div>
        </div>
        <!-- @openLightBox="(data) => {$emit('openLightBox', data)}" This is the review gallery -->
        <light-box v-if="gallery.value" :active="gallery.selected" :images="gallery.images" @closeLightBox="closeLightBox" />
    </div>
</template>

<script>

import Avatar from "./Avatar.vue"
import ImageReview from '../Image.vue'
import LightBox from '@/modules/products/components/modals/LightBox.vue'
import { ProductService } from '@/modules/products/services/product-service.js'
import { mapGetters } from "vuex"
import IconLike from '../../../../common/svg/iconLike.vue'
import IconCheckCircle from '../../../account/assets/svg/iconCheckCircle.vue'

const service = new ProductService()
export default {
    name: "Review",
    components: {
        Avatar,
        ImageReview,
        LightBox,
        IconLike,
        IconCheckCircle,
    },
    props: {
        review: {
            type: Object
        },
        userReview: {
            type: Boolean,
        }
    },
    data() {
        return {
            gallery: { 
                value: false,
                selected: null,  
                images: null 
            },
            previewImgs: null,
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/userData",
            isLogged: "auth/isLogged",
        }),
        checkIsLiked() {
            if(this.user?.entity?.code) {
                let check = this.review.like.filter(like => like.customer_id == this.user?.entity?.code) 
                return check[0];
            }
            return false
        },
        checkIsDisliked() {
            if(this.user?.entity?.code) {
                let check = this.review.dislike.filter(dislike => dislike.customer_id == this.user?.entity?.code)
                return check[0];
            }
            return false;
        },
        reviewImages() {
            let reviewImg = [];
            this.review.images.map((img) => {
                reviewImg.push({picture_url: img});
            });
            return reviewImg;
        },
        checkCustomer(){
            if(this.user?.entity?.code) return this.review.customer.code == this.user?.entity?.code;
            return false;
        }
    },
    methods: {
        async vote(value){
            try {
                if(!this.isLogged){
                    this.$store.commit("auth/OPEN_MODAL", true)
                    return
                }
                await service.voteReview(this.review._id, value)
                this.$emit("updateReviews")
            } catch (error) {
                console.log(error);
            }
        },
        openLightBox(img){
            this.$emit('showLightBox', 8)
            this.gallery = { value: true, selected: img, images: this.reviewImages }
        },
        closeLightBox(){
            this.$emit('showLightBox', 9)
            this.gallery.value = false
        },
        reportReview(id){
            if(!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true);
                return;
            }else {
                this.$emit('reportReview', id);
            }
        },    
        onResize() { //useful
            this.previewImgs = window.matchMedia('(min-width: 768px)').matches ? 3: 4;
        },
    },
    created(){
        window.addEventListener("resize", this.onResize);
        this.onResize()
    },
    destroyed(){
        window.removeEventListener("resize", this.onResize);
    }
    
}
</script>

<style scoped>
    .show-rest-img {
        content: '';
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100px;
        height: 100px;
        background-color: #121C23B3 !important; /* dark-main-navy */
    } 
    .like {
        display: flex;
        align-items: center;
        color: var(--dark-gray-200) !important;
    }
    .like > svg {
        transition: var(--transition-1) !important;
    }
    .like.active {
        color: var(--blue-600) !important;
    }
    .like:hover > svg {
        fill: var(--blue-600);
    }
    .like:hover {
        color: var(--blue-600)!important;
    }
    .like.active > svg {
        fill: var(--blue-600);
    }
    .image__wrapper::v-deep {
        border: 1px solid var(--light-gray-100);
    }
    .image__wrapper::v-deep, .image__wrapper::v-deep img{
        width: 100px !important;
        height: 100px;
        border-radius: 8px;
        object-fit: cover !important;
    }
    .container-img:hover > .image__wrapper::v-deep img{
        transform: scale(1.1);
    }
    .container-img {
        overflow: hidden;
        border-radius: 10px;
    }
    .container-img:hover::before{
        cursor: pointer;
        content: '';
        background: #212e3d58;
        position: absolute;
        width: 100%;
        z-index: 2;
        height: 100%;
        animation: show-mask 0.3s;
        border-radius: 10px;
    }
    @keyframes show-mask {
        from{
            opacity: 0;
        }to{
            opacity: 1;
        }
    }
    .blink-enter-active {
        transition: opacity .5s ease-out;
    }
    .blink-enter, .blink-leave-to {
        opacity: 0;
    }
</style>
        