
<template>
    <svg :width="size+'px'" viewBox="0 0 130.732 249">
        <path id="Icon_awesome-bluetooth-b" data-name="Icon awesome-bluetooth-b" d="M142.747-402.854l45.059-50.212L116.8-529.326v100.358L74.886-470.856,59.634-455.6l52.554,52.728L59.634-350.137l15.252,15.252,41.878-41.877,1.318,96.436L190.366-352.6Zm19.871-50.114-24.3,24.327-.164-48.785Zm-24.3,75.9,24.3,24.3-24.469,24.479Z" transform="translate(-59.634 529.326)" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name:'iconBluetooth',
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
    }
}
</script>

<style scoped>
</style>

