<template> 
    <section class="social-container d-flex flex-column position-relative" :class="{'review-opened': formReview}">
        <div class="position-absolute w-100">
            <scroll-view> <!-- nav-marker -->
                <template>
                    <scroll-marker class="mt-0" name="reviewsForm" key="reviewsForm"/>
                </template>
            </scroll-view>

            <create-review v-if="formReview" ref="createReview" style="z-index: 5;" @formReview="openReviews" :editData="myReview" 
            @updateReviews="updateReviews" @formQuestion="formQuestion = !formQuestion; formReview = false" 
            :code="product.codarticulo" :hasUserReview="hasUserReview" @changeHeight="assignDistance" @cancelReview="cancelReview"/>

            <create-question v-if="formQuestion" @formReview="formReview = !formReview; formQuestion = false" 
            @updateQuestions="updateQuestions" @formQuestion="formQuestion = !formQuestion; formReview = false" :hasUserReview="hasUserReview"
            @close="formQuestion = false;" :code="product.codarticulo"/>
        </div>

        <div class="reviews-question-wrapper position-relative bg-white" :class="{'gradient-effect-before': !formReview && !formQuestion, 
        'review-opened': formReview, 'question-opened': formQuestion}" :style="{marginTop: formReview ? distanceFromForm+'px' :'0px'}">
            <div class="d-flex reviews-tab justify-content-between position-relative">
                <ul class="unstyled-list d-flex p-0">
                    <li v-for="item in productSocialsTab" :key="item.value" class="tab-option me-5" :class="[activeTab.value === item.value ? 'weight-700' : 'weight-300 color-gray-1001']"
                    @click="activeTab = item" :title="$t(item.title)">
                        {{$t(item.title)}} ({{item.total}})
                    </li>
                </ul>
                <toggle-review-question-form v-if="!formReview && !formQuestion" class="d-none d-md-flex d-lg-none d-xxl-flex justify-content-around justify-content-sm-end"
                :showReviews="!hasUserReview" @toggleReviews="createReview" @toggleQuestions="createQuestion"/>
            </div>
            <!-- the section indicator -->
            <div class="position-relative"> 
                <div class="selected-tab" :class="{'move': activeTab.value == 'questions'}" />
                <hr class="mt-1 mb-2 color-gray-1001">
            </div>

            <toggle-review-question-form v-if="!formReview && !formQuestion" class="d-flex d-md-none d-lg-flex d-xxl-none mt-3" 
            :showReviews="!hasUserReview" @toggleReviews="createReview" @toggleQuestions="createQuestion"/>

            <div v-if="(activeTab.value === 'questions' && showQuestionsFilters) || (activeTab.value === 'reviews' && showReviewsFilters)" class="row mx-0 my-4 py-xxl-3 justify-content-end"> 
                <div class="col-12">
                    <filter-socials v-if="activeTab.value == 'reviews' && showReviewsFilters" :selectedOption="reviewsSelectedOption"
                    :filterOptions="reviewFilterOptions" @filterBy="changeReviewsFilter" @setKeyword="setReviewsKeyword"/>
                    <filter-socials v-if="activeTab.value == 'questions' && showQuestionsFilters" :selectedOption="questionsSelectedOption"
                    :filterOptions="questionFilterOptions" @filterBy="changeQuestionsFilter" @setKeyword="setQuestionsKeyword"/>
                </div>
            </div>
            <section v-show="activeTab.value == 'reviews'" class="show-animation">
                <slot /> <!-- this is for the marker -->
                <div v-if="reviews.length != 0" :key="reviews.length">
                    <div v-for="review in reviews" :key="review._id">
                        <review :review="review" @edit="editReview" @reportReview="(id) => { openReport(id, 'review') }" 
                        @showLightBox="(data) => {$emit('showLightBox', data)}" @deleteReview="(id) => { openDeleteType(id, 'review') }"
                        @errorReview="$emit('errorReview')" @closeEdit="formReview = false; myReview = null;" 
                        @updateReviews="updateReviews" />
                        <hr class="mt-3">
                    </div>
                    <main-pagination disableScroll class="mt-5" :total="reviewsTotalPages" :current="reviewsCurrentPage" @setPage="setReviewsPage" />
                </div>
                <without-results v-else @click="createReview" class="no-results my-5" title="product.none_reviews_title" titleButton="product.none_reviews_btn" description="product.none_reviews_desc">
                    <template #main-icon>
                        <icon-no-reviews :size="85" color="var(--gray-1001)"/>
                    </template>                
                    <template #button-icon>
                        <icon-comment :size="20" color="var(--white)" class="me-2 pe-1"  />
                    </template>
                </without-results>
            </section>
            <section v-show="activeTab.value == 'questions'" class="show-animation">
                <div v-if="questions.length != 0">
                    <div v-for="question in questions" :key="question.id">
                        <question :question="question" :getHeight="activeTab.value" @showLightBox="(data) => {$emit('showLightBox', data)}" @reload="getQuestions"
                        @updateQuestion="updateQuestions" @reportAnswer="(id) => { openReport(id, 'answer') }" @openDeleteAnswer="(id) => { openDeleteType(id, 'answer') }" 
                        @openDeleteQuestion="(id) => { openDeleteType(id, 'question') }" />
                        <hr class="mt-3">
                    </div>
                    <main-pagination disableScroll class="mt-5" :total="questionsTotalPages" :current="questionsCurrentPage" @setPage="setQuestionsPage" />
                </div>
                <without-results v-else @click="createQuestion" class="no-results my-5" title="product.none_questions_title" titleButton="product.none_questions_btn" description="product.none_questions_desc">
                    <template #main-icon>
                        <icon-no-questions :size="85" color="var(--gray-1001)"/>
                    </template>                
                    <template #button-icon>
                        <icon-comment class="me-2 pe-1" color="var(--white)" :size="20" />
                    </template>
                </without-results>
            </section> 
        </div>
        <confirm-delete-type v-if="confirmDelete.value" :type="confirmDelete.type" @confirm="deleteType" @closeModal="confirmDelete.value = false" />
        <report-question-review v-if="report.show" :report="report" @closeModal="closeModalReport" />
    </section>
</template>

<script>
import WithoutResults from "@/common/components/modals/WithoutResults.vue"
import ConfirmDeleteType from "./modals/ConfirmDeleteType";
import IconNoReviews from "@/modules/products/assets/svg/iconNoReviews.vue"
import IconNoQuestions from  '../assets/svg/iconNoQuestions.vue'
import IconComment from '../assets/svg/iconComment.vue'
import Review from './chat/Review.vue'
import Question from './chat/Question.vue'
import CreateReview from './CreateReview.vue'
import CreateQuestion from './CreateQuestion.vue'
import ToggleReviewQuestionForm from "./buttons/ToggleReviewQuestionForm.vue"
import FilterSocials from "./filters/FilterSocials.vue"
import ReportQuestionReview from './modals/ReportQuestionReview.vue'
import { mapGetters } from "vuex";
import { $scrollview } from 'vue-scrollview'
import MainPagination from '../../../common/components/pagination/MainPagination.vue'

import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()

export default {
    name: "ProductSocials",
    components: {
        Review,
        Question,
        CreateReview,
        CreateQuestion,
        WithoutResults,
        ToggleReviewQuestionForm,
        ReportQuestionReview,
        FilterSocials,
        MainPagination,
        ConfirmDeleteType,
        IconNoReviews,
        IconNoQuestions,
        IconComment,
    },
    data() {
        return {
            activeTab: {
                title: 'product.reviews',
                value: 'reviews'
            },
            productSocialsTab: [
                { title: 'product.reviews', value: 'reviews', total:  0},
                { title: 'product.questions', value: 'questions', total:  0},
            ],
            formReview: false,
            formQuestion: false,
            myReview: null,
            distanceFromForm: 720,
            reviews: [], //the reviews & questions array.
            questions: [],
            reviewsTotalPages: 10, //totalPages
            questionsTotalPages: 10,
            reviewsCurrentPage: 1, //current page in pagination
            questionsCurrentPage: 1,
            hasUserReview: false,
            reviewsFilter: 'recent', //reviews & questions filter
            questionsFilter: 'recent',
            reviewFilterOptions: [ //reviews & questions filters options
                {
                    value: 'recent',
                    title: 'product.recent'
                },
                {
                    value: 'most_popular',
                    title: 'product.more_popular'
                },
                {
                    value: 'higher_rating',
                    title: 'product.highest_score'
                },
                {
                    value: 'lower_rating',
                    title: 'product.lower_score'
                }
            ],
            questionFilterOptions: [
                {
                    value: 'recent',
                    title: 'product.recent'
                },
                {
                    value: 'least_answers',
                    title: 'product.least_answers'
                },
                {
                    value: 'most_answers',
                    title: 'product.most_answers'
                },
                {
                    value: 'oldest',
                    title: 'product.oldest'
                }
            ],
            questionsSelectedOption: { value: 'recent', title: 'product.recent' }, //default filter option
            reviewsSelectedOption: { value: 'recent', title: 'product.recent' },
            keywordCounter: null, //reviews & questions keywords
            reviewsKeywords: '',
            questionsKeywords: '',
            showReviewsFilters: false, //checking if there are actual questions or reviews on this product
            showQuestionsFilters: false,
            confirmDelete: {
                id: '',
                show: false,
                type: 'answer'
            },
            report: {
                show: false,
                type: 'review',
            }
        }
    },
    props: {
        product: {
            type: Object
        },
    },
    computed: {
        ...mapGetters({
            user: "auth/userData",
            isLogged: "auth/isLogged",
            isPageDisable: "common/isPageDisable"
        }),
    },
    methods: {
        async getQuestions(){ //this whole method has to manage the total, the pagination, and the reviews themselves.
            try {
                const req = await service.getQuestionsPage(this.product._id, this.questionsCurrentPage, this.questionsFilter, this.questionsKeywords);
                this.questions = req.data.docs;
                this.questionsTotalPages = req.data.totalPages;
                return req.data.totalDocs; //getting the total number of questions
            } catch (e) {
                console.log(e);
            }
        },        
        async getReviews() { //this whole method has to manage the total, the pagination, and the reviews themselves.
            try {
                const req = await service.getReviewsPage(this.product._id, this.reviewsCurrentPage, this.reviewsFilter, this.reviewsKeywords);
                this.reviews = req.data.docs;
                this.reviewsTotalPages = req.data.totalPages;
                this.hasUserReview = req.data.userHasReview;
                return req.data.totalDocs; //getting the total number of reviews
            } catch (e) {
                console.log(e);
            }
        },
        async updateReviews(action){ //Decreasing to our review counter
            if (action === 'created') this.productSocialsTab[0].total++
            this.activeTab = this.productSocialsTab[0];
            this.$emit("reviewsUpdated");
            await this.getReviews();
        },
        async updateQuestions(){
            this.productSocialsTab[1].total++ //Adding to our question counter
            this.activeTab = this.productSocialsTab[1];
            await this.getQuestions();
        },
        async setReviewsPage(data) {
            this.reviewsCurrentPage = data;
            await this.getReviews();
            $scrollview.scrollToComponent("reviewsForm", 150);
        },
        async setQuestionsPage(data) {
            this.questionsCurrentPage = data;
            await this.getQuestions();
            $scrollview.scrollToComponent("reviewsForm", 150);
        },
        async setReviewsKeyword(val) {
            clearTimeout(this.keywordCounter);
            this.keywordCounter = setTimeout(async () => {
                this.reviewsKeywords = val;
                await this.getReviews();
            }, 500);
        },        
        async setQuestionsKeyword(val) {
            clearTimeout(this.keywordCounter);
            this.keywordCounter = setTimeout(async () => {
                this.questionsKeywords = val;
                await this.getQuestions();
            },  500);
        },
        createReview(){
            if(this.isPageDisable){
                this.$store.commit("common/SET_DISABLE_MODAL", true)
                return
            }
            if(!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true)
                return
            }
            this.openReviews();
        },
        createQuestion(){
            if(this.isPageDisable){
                this.$store.commit("common/SET_DISABLE_MODAL", true)
                return
            }
            if(!this.isLogged){
                this.$store.commit("auth/OPEN_MODAL", true)
                return
            }
            this.formQuestion = !this.formQuestion;
        },
        openReviews() {
            if (this.formReview) this.myReview = null; //if we already had this form opened we close it
            this.formReview = !this.formReview;
            this.formQuestion = false;
            $scrollview.scrollToComponent("reviewsForm", 150);
        },
        openDeleteType(id, type){
            this.confirmDelete = {id, type, value: true};
        },
        editReview(review){
            this.myReview = {...review};
            this.openReviews();
            $scrollview.scrollToComponent("reviewsForm", 150);
        },
        cancelReview(){
            this.openReviews();
        },
        async changeReviewsFilter({value, title}){
            this.reviewsFilter = value;
            this.reviewsCurrentPage = 1;
            this.reviewsSelectedOption =  { value, title: `product.${title}` };
            await this.getReviews();
        },
        async changeQuestionsFilter({value, title}){
            this.questionsFilter = value;
            this.questionsCurrentPage = 1;
            this.questionsSelectedOption =  { value, title: `product.${title}` };
            await this.getQuestions();
        },
        assignDistance(data) {
            this.distanceFromForm = (150 - data) === 0 ? 750 : 720 - (150 - data);  //Inicia en 150 y tiene un margin top de 710
            //when the count editing or creating goes back to 0 it reduces the margin more than necessary.
        },
        openReviewsTab(){
            this.activeTab = this.productSocialsTab[0];
        },
        openComments(){
            if (!this.formReview) this.openReviews()
        },
        deleteType(){ //here we will delete depending on the type
            this.confirmDelete.value = false;
            switch (this.confirmDelete.type) {
                case 'review':
                    this.deleteReview();
                break;
                case 'answer':
                    this.deleteAnswer()
                    break;
                case 'question':
                    this.deleteQuestion()
                break;
                default:
                    this.$store.commit("common/SET_SHORT_NOTIFY", { text: 'common.notification.unexpected_error', type: 'danger', show: true})
                break;
            }
        },
        async deleteReview() {
            try {
                let req = await service.deleteReview(this.confirmDelete.id);
                this.$store.commit("common/SET_SHORT_NOTIFY", {
                    show: true,
                    type: 'success', 
                    title: this.$t('product.review_removed_title'), 
                    text: this.$t('product.review_removed_text'), 
                });
                if (req.data.code !== 3267 && req.data.code !== 200) this.$refs.createReview.showError()

                this.productSocialsTab[0].total--;
                await this.getReviews();

            } catch (error) {
                this.$refs.createReview.showError();
                console.log(error);
            }finally {
                this.$emit("reviewsUpdated");
            }
        },
        async deleteAnswer() {
            try {
                await service.deleteAnswer(this.confirmDelete.id)
                await this.getQuestions();
            } catch (error) {
                console.log(error);
            } finally {
                this.showDeleteAnswer = false;
            }
        },
        async deleteQuestion() {
            try {
                await service.deleteQuestion(this.confirmDelete.id);
                this.productSocialsTab[1].total--
                await this.getQuestions();
            } catch (error) {
                console.log(error);
            }
        },
        openReport(id, type){
            this.report = { show: true, id, type };
        },
        closeModalReport(){
            this.report = { show: false, id: '', type: 'review' };
        }
    },
    watch: {
        productSocialsTab: {
            handler(val){
                this.showReviewsFilters = val[0].total > 0;
                this.showQuestionsFilters = val[1].total > 0;
            },
            deep: true
        },
    },
    //set the number of reviews  to the first total
    async created() {
        this.productSocialsTab[0].total = await this.getReviews(); //getting the number of reviews
        this.showReviewsFilters = this.productSocialsTab[0].total > 0; //getting if the product actually has reviews

        this.productSocialsTab[1].total = await this.getQuestions(); //getting the number of questions
        this.showQuestionsFilters = this.productSocialsTab[1].total > 0; //getting if the product actually has questions
    },
}
</script>

<style scoped>
    .social-container {
        min-height: 315px;
        transition: var(--transition-1);
    }
    .social-container.review-opened {
        min-height: fit-content;
        transition: var(--transition-1);
    }
    .reviews-question-wrapper {
        transition: all 0.5s ease-in-out !important;
        z-index: 8; /*11 when confirm-delete-type is showing*/
    }
    .reviews-question-wrapper.review-opened {
        transition: all 0.5s ease-in-out !important;
    }
    .reviews-question-wrapper.question-opened {
        margin-top: 355px !important;
        transition: all 0.5s ease-in-out !important;
    }
    .gradient-effect-before {
        transition: var(--transition-1);
        transition-delay: .20s;
    }
    .no-results::v-deep > h6 {
        font-size: 18px !important;
    }
    .reviews-tab > ul > li {
        font-size: 18px;
        cursor: pointer;
    }
    .reviews-tab > ul > li:hover {
        font-weight: 700 !important;
    }
    .selected-tab {
        position: absolute;
        z-index: 3;
        width: 50px;
        left: 0;
        background: var(--light-main-red);
        top: -1px;
        height: 4px;
        transition: all .3s ease-in-out;
    }
    .tab-option {
        min-width: 111px;
    }
    .select-container::v-deep {
        height: 40px;
    }
    .show-animation {
        margin-top: 0 !important;
        animation: show 0.5s;
    }
    @keyframes show {
        from {
            opacity: 0;
            transform: translateY(10px);
        }to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
    li::before {
        display: block;
        content: attr(title);
        height: 0;

        font-weight: 700;
        overflow: hidden;
        visibility: hidden;
    }
    .comment-question {
        right: 0;
    }
    .selected-tab.move {
        left: clamp(160px, 40vw, 160px);
    }
    @media (min-width : 576px) {
        .selected-tab.move {
            left: calc(22% + 48px);
        }
        .reviews-question-wrapper.question-opened {
            margin-top: 335px !important;
            transition: all 0.5s ease-in-out !important;
        }
    }
    @media (min-width : 768px) {
        .selected-tab.move {
            left: calc(22% + 5px);
        }
    }
    @media (min-width : 992px) {
        .selected-tab.move {
            left: calc(22% + 60px);
        }
        .reviews-tab > ul > li {
            font-size: 20px;
            cursor: pointer;
        }
    }
    @media (min-width : 1200px) {
        .selected-tab.move {
            left: calc(22% + 21px);
        }
        .input-group {
            max-width: 380px;
        }
    }
    @media (min-width : 1400px) {
        .selected-tab.move{
            left: calc(21% + 3px);
        }
        .comment-question{
            top: 0;
        }
    }
</style>
