import { render, staticRenderFns } from "./iconLike.vue?vue&type=template&id=6aa0cb39"
import script from "./iconLike.vue?vue&type=script&lang=js"
export * from "./iconLike.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports