<template>
<div>
    <transition name="product-rate-form">
        <form @submit.prevent="send">
            <div class="d-flex flex-column flex-xl-row align-items-xl-center mb-5 position-relative">
                <h5 class="title-18 mb-0 cursor-pointer" @click.prevent="$emit('formQuestion')"> <icon-edit :size="18" :color="'var(--gray-1001)'" class="me-2" style="margin-bottom: 6px;" />{{$t('product.create_question')}}</h5>
                <toggle-review-question-form class="d-flex justify-content-between ms-auto mt-3 mt-xl-0" :showReviews="!hasUserReview"  @toggleReviews="$emit('formReview')"  @toggleQuestions="$emit('formQuestion')"/>
            </div>      
            <div class="row">
                <div v-if="!isLogged" class="col-md-6">
                    <main-input placeholder="auth.enter_your_name" :title="$t('auth.name')" class="mb-4" :id="'name'" ref="name" :value="form.full_name" @changeValue="(data) => {form.full_name = data}">
                        <icon-person color="var(--dark-gray-200)" :size="15"/>
                    </main-input>
                </div>
                <div v-if="!isLogged" class="col-md-6">
                    <main-input placeholder="Example@gmail.com" :title="$t('auth.email')" class="mb-4" id="email" ref="email" type="email" :value="form.email" @changeValue="(data) => {form.email = data}">
                        <icon-email color="var(--dark-gray-200)" :size="15"/>
                    </main-input>
                </div>
            </div>
            <div class="form-group">
                <label for="review" class="title-15 d-flex">
                    {{$t('product.questions')}} 
                    <span class="color-dark-gray-200 weight-500 ms-2">({{maxLength}} {{$t('product.characters')}} max)</span>
                    <span class="weight-500 ms-auto" :class="[form.question.length === maxLength ? 'color-dark-main-red':'color-dark-gray-200']">{{form.question.length}} / {{maxLength}}</span>
                </label>
                <textarea v-model="form.question" class="form-control" id="review" rows="5" placeholder="Escribe tu pregunta acerca del producto..." :maxlength="maxLength" :class="{ 'textarea-invalid': $v.form.question.$error }" />
            </div>
            <div class="d-flex mt-4">
                <main-button title="home.contact.send" id="send" icon class="ms-auto">
                    <icon-send class="me-3" :size="15"/>
                </main-button>
            </div>
        </form>
    </transition>
    <error-review-question v-if="error" @closeModal="reload" description="product.error_question"/>
</div>
</template>

<script>
import MainInput from '../../../common/components/inputs/MainInput.vue'
import IconEdit from '../../checkout/assets/svg/iconEdit.vue'
import ToggleReviewQuestionForm from "../components/buttons/ToggleReviewQuestionForm.vue"
import MainButton from '../../../common/components/buttons/MainButton.vue'
import IconSend from '../../../common/svg/iconSend.vue'
import IconPerson from '../../../common/svg/iconPerson.vue'
import IconEmail from '../../auth/assets/svg/iconEmail.vue'
import { required, maxLength} from 'vuelidate/lib/validators'
import { mapGetters } from "vuex"
import { ProductService } from '@/modules/products/services/product-service.js'

import ErrorReviewQuestion from "@/modules/products/components/modals/ErrorReviewQuestion.vue";
const service = new ProductService();
export default {
    components: {
        ToggleReviewQuestionForm,
        MainInput,
        MainButton, 
        ErrorReviewQuestion,
        IconEdit, 
        IconSend, 
        IconPerson, 
        IconEmail,
    },
    name: "CreateQuestion",
    data() {
        return {
            form: {
                full_name: '',
                email: '',
                question: '',
            },
            error: false,
            maxLength: 120
        }
    },
    computed:{
        ...mapGetters({
            isLogged: "auth/isLogged",
        })
    },
    validations: {
        form: {
            question: {
                required,
                maxLength: maxLength(300)
            },
        }
    },
    props: {
        hasUserReview: {
            type: Boolean
        },
        code: {
            type: Number
        }
    },
    methods: {
        async send(){
            if(!this.isLogged){
                this.$refs.name.$v.$touch()
                this.$refs.email.$v.$touch()
                this.$v.$touch()
                if (this.$refs.name.$v.$invalid || this.$refs.email.$v.$invalid || this.$v.$invalid) return;
            }
            this.$v.form.question.$touch()
            if(this.$v.form.question.$error) return;
            try {
                this.cleanQuestion()
                await service.createQuestion(this.code, this.form)

                let notify = { text: this.$t('product.saved_question'), type: 'success', show: true,}
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                
                this.form = {full_name: '',  email: '', question: ''}
                this.$emit('updateQuestions');
                //
                this.$emit("close")
            } catch (error) {
                this.error = true;
            }
        },
        cleanQuestion(){
            let notAllowed = ['?','¿', '!']
            notAllowed.map(c => {this.form.question = this.form.question.replaceAll(c, '')})
        },
        reload(){ this.$router.go() }
    }
}
</script>

<style scoped>
    form {
        transition: var(--transition-1);
    }
    .form-group > textarea {
        border-radius: 10px;
        border-color: var(--gray-100);
        resize: none;
    }
    .vue-dropzone {
        border-style: dashed;
        border-radius: 15px;
    }
    .form-group > textarea::placeholder {
        font-size: 14px;
    }
    .form-group > .textarea-invalid {
        border-color: var(--main-red);
    }
    .toggler-container {
        width: 60px;
        height: 26px;
    }
    @media (min-width : 1400px) {
        .comment-question {
            top: 0;
        }
    }
</style>
