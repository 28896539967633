<template>
    <svg :width="size+'px'" viewBox="0 0 199 178.009">
        <g id="Group_6580" data-name="Group 6580" transform="translate(223.141 -6.91)">
            <ellipse id="Ellipse_380" data-name="Ellipse 380" cx="99.5" cy="8.158" rx="99.5" ry="8.158" transform="translate(-223.141 168.603)" fill="#f0f4f9"/>
            <g id="Group_6556" data-name="Group 6556">
                <path id="Path_2642" data-name="Path 2642" d="M-55.318,26.006h-4.415a.022.022,0,0,0-.022.023V88.912A10.165,10.165,0,0,1-69.919,99.077h-87.1v5.679a10.165,10.165,0,0,0,10.166,10.164h55.881l32.008,17.838a1.058,1.058,0,0,0,1.442-.4,1.058,1.058,0,0,0,.1-.809l-4.641-16.628h6.75a10.167,10.167,0,0,0,10.166-10.166V36.173A10.167,10.167,0,0,0-55.318,26.006Z" fill="#c6d4db"/>
                <path id="Path_2643" data-name="Path 2643" d="M-79.182,90.92A10.144,10.144,0,0,0-69.038,80.777V17.054A10.144,10.144,0,0,0-79.182,6.91H-191.987a10.144,10.144,0,0,0-10.144,10.145v63.72A10.144,10.144,0,0,0-191.989,90.92h6.438l-4.641,16.628a1.059,1.059,0,0,0,.735,1.3,1.058,1.058,0,0,0,.8-.095l32.008-17.837Zm-93.6-64.525h74.4a3.2,3.2,0,0,1,3.2,3.2,3.2,3.2,0,0,1-3.2,3.2h-74.4a3.2,3.2,0,0,1-3.2-3.2A3.2,3.2,0,0,1-172.783,26.4Zm0,18.783h74.4a3.2,3.2,0,0,1,3.2,3.2,3.2,3.2,0,0,1-3.2,3.2h-74.4a3.2,3.2,0,0,1-3.2-3.2A3.2,3.2,0,0,1-172.783,45.178Zm-3.2,21.985a3.2,3.2,0,0,1,3.2-3.2h74.4a3.2,3.2,0,0,1,3.2,3.2,3.2,3.2,0,0,1-3.2,3.2h-74.4A3.2,3.2,0,0,1-175.986,67.163Z" fill="#c6d4db"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'iconNoReviews',
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        }
    }
}
</script>

<style scoped>

</style>

