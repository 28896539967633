<template>
    <icon-base :fill="color" :width="size + 'px'" viewBox="0 0 100 92.276">
        <g id="Question_icon" data-name="Question icon" transform="translate(-611 -1868.305)">
            <ellipse id="Ellipse_409" data-name="Ellipse 409" cx="50" cy="4.099" rx="50" ry="4.099" transform="translate(611 1952.383)" fill="#f0f4f9"/>
            <path id="Path_2637" data-name="Path 2637" d="M-2722.92,169.608a12.651,12.651,0,0,0-12.642,12.659,12.651,12.651,0,0,0,12.658,12.642,12.652,12.652,0,0,0,12.643-12.659,12.652,12.652,0,0,0-12.659-12.642Zm5,18.809a.75.75,0,0,1-.2-.016,1.336,1.336,0,0,1-.7-.373l-4.09-4.091-4.1,4.09a1.239,1.239,0,0,1-.682.374.8.8,0,0,1-.39,0,1.2,1.2,0,0,1-.99-1.364,1.287,1.287,0,0,1,.374-.7l4.1-4.089-4.089-4.106a1.262,1.262,0,0,1-.389-.681,1.2,1.2,0,0,1,.324-1.039,1.216,1.216,0,0,1,1.688,0l4.154,4.138,4.139-4.138a1.215,1.215,0,0,1,1.687,0,1.2,1.2,0,0,1,.325,1.039,1.238,1.238,0,0,1-.373.681l-4.106,4.106,4.106,4.089a1.419,1.419,0,0,1,.389.7c0,.065.016.13.016.195a1.194,1.194,0,0,1-1.2,1.185Z" transform="translate(3415.982 1698.697)" fill="#bc2c35"/>
            <path id="Union_38" data-name="Union 38" d="M-2000.465,18590.289a.53.53,0,0,1-.364-.658l2.328-8.355h-3.392a5.114,5.114,0,0,1-5.109-5.109v-34.459a5.11,5.11,0,0,1,5.109-5.109h2.221s.009,0,.009.01v31.6a5.114,5.114,0,0,0,5.109,5.107h43.772v2.852a5.111,5.111,0,0,1-5.109,5.109h-28.082l-16.082,8.963a.572.572,0,0,1-.27.068A.566.566,0,0,1-2000.465,18590.289Zm65.574-12.111-16.082-8.963H-1989.9a5.1,5.1,0,0,1-5.095-5.1V18532.1a5.1,5.1,0,0,1,5.1-5.1h42.088c-.014.285-.023.574-.023.863a17.638,17.638,0,0,0,17.617,17.617,17.629,17.629,0,0,0,2.1-.131v18.77a5.1,5.1,0,0,1-5.1,5.1h-3.233l2.333,8.355a.542.542,0,0,1-.047.4.532.532,0,0,1-.466.273A.552.552,0,0,1-1934.891,18578.178Zm-28.8-23.465a2.512,2.512,0,0,0-.023,3.547,2.51,2.51,0,0,0,3.546.023v-.006a2.437,2.437,0,0,0,.746-1.781,2.507,2.507,0,0,0-.723-1.76,2.506,2.506,0,0,0-1.786-.744A2.5,2.5,0,0,0-1963.687,18554.713Zm3.271-2.6a2.164,2.164,0,0,0,.639-1.588,2.158,2.158,0,0,1,.453-1.3,7.929,7.929,0,0,1,1.134-1.232l1.376-1.207a5.541,5.541,0,0,0,1.241-1.652h0a4.968,4.968,0,0,0,.513-2.254,5.2,5.2,0,0,0-1.95-4.092,6.909,6.909,0,0,0-4.638-1.66,6.325,6.325,0,0,0-4.572,1.773,4.936,4.936,0,0,0-1.838,3.354,2.4,2.4,0,0,0,.438,1.381,1.641,1.641,0,0,0,1.432.668,1.874,1.874,0,0,0,1.292-.467,1.511,1.511,0,0,0,.574-1.051,2.273,2.273,0,0,1,.709-1.26,2.5,2.5,0,0,1,1.843-.629,2.779,2.779,0,0,1,1.787.629,1.8,1.8,0,0,1,.765,1.443,2.085,2.085,0,0,1-.471,1.24,8.885,8.885,0,0,1-1.176,1.264l-1.432,1.283a6.342,6.342,0,0,0-1.292,1.629,4.181,4.181,0,0,0-.532,2.02,2.4,2.4,0,0,0,.62,1.676,2.016,2.016,0,0,0,1.521.676A2.132,2.132,0,0,0-1960.416,18552.111Z" transform="translate(2623.278 -16646.914)" fill="#c6d4db" stroke="rgba(0,0,0,0)" stroke-width="1"/>
        </g>
    </icon-base>
</template>

<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconDeleteQuestion',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
    }
}
</script>

<style scoped>

</style>
