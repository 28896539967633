<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 218.454 249">
        <g id="Group_4682" data-name="Group 4682" transform="translate(-278.73 266.37)">
            <path id="Path_2178" data-name="Path 2178" d="M490.441-120.443a11.114,11.114,0,0,1-2.417-10.345,26.157,26.157,0,0,0,.934-5.583,26.28,26.28,0,0,0-24.713-27.758,131.966,131.966,0,0,0-30.541-1.987c-8.431.215-10.861-2.686-7.874-10.633a181.317,181.317,0,0,0,8.574-32.683,63.276,63.276,0,0,0-4.63-39.256,28.123,28.123,0,0,0-27.423-17.651c-10.019,2.144-13.035,7.819-13.506,25.323-.072,1.073-.072,2.116-.072,3.189a56.364,56.364,0,0,1-13.5,39.1c-8.89,10.5-18.952,20.093-27.155,31.067a25.232,25.232,0,0,1-25.965,12.219c-7.546-1.228-9.633,2.187-9.418,9.476.486,17.291.214,34.626.214,51.919.014,17.677.215,35.34-.028,52.974-.1,6.1,1.258,9.275,8.175,8.659a105.906,105.906,0,0,1,45.2,7.732c29.482,10.39,60.608,6.988,91.2,5.917a16.964,16.964,0,0,0,16.507-9.874c3.543-12.9,13.261-22.852,15.6-36.041a16.1,16.1,0,0,0,.6-7.288A19.792,19.792,0,0,1,493.3-87.689,28.471,28.471,0,0,0,490.441-120.443Z"/>
            <path id="Path_2179" data-name="Path 2179" d="M302.1-93.09c-.028-17.991-.544-36.013.129-54.02.37-10.16-6.032-8.274-11.733-8.258s-11.9-1.916-11.762,8.217q.7,53.464.143,106.909c-.1,9.476,5.888,7.99,11.162,7.7s12.56,2.9,12.246-7.674C301.77-57.805,302.1-75.453,302.1-93.09Z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconLike',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>