<template>
    <main-modal @closeModal="$emit('closeModal')">
        <form @submit.prevent="send">
            <div class="form-group">
                <label for="review" class="d-flex title-15 mt-2"> 
                    <div class="d-flex flex-column">
                        {{$t('product.answer')}}
                        <span class="color-dark-gray-200 weight-500 d-none d-sm-flex">(155 {{$t('product.characters')}})</span>
                    </div>
                    <span class="weight-500 ms-auto mt-auto" :class="[answer.selected.answer.length === 155 ? 'color-dark-main-red':'color-dark-gray-200']">
                        {{answer.selected.answer.length}} / 155
                    </span>
                </label>
                <textarea v-model="answer_value" ref="answer" class="form-control mt-3" id="review" rows="5" :placeholder="$t('product.write_answer')" maxlength="155" :class="{ 'textarea-invalid': $v.answer_value.$error }" />
            </div>
            <div class="d-flex mt-4">
                <main-button title="home.contact.send" class="ms-auto" id="send" icon>
                    <icon-send class="me-3" :size="15"/>
                </main-button>
            </div>
        </form>
    </main-modal>
</template>

<script>
import { required, maxLength} from 'vuelidate/lib/validators'
import MainModal from '../../../common/components/modals/MainModal.vue'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import IconSend from '../../../common/svg/iconSend.vue'

import { ProductService } from '@/modules/products/services/product-service.js'
const service = new ProductService()

export default {
    components: {
        MainModal,
        MainButton, 
        IconSend,
    },
    name: "CreateAnswer",
    data() {
        return {
            answer_value: ''
        }
    },
    props: {
        answer: {
            type: Object,
            default: () => {
                return {selected: {answer: ''}}
            }
        }
    },
    validations: {
        answer_value: {
            required,
            maxLength: maxLength(300)
        },
    },
    methods: {
        async send(){
            this.$v.$touch();
            if (this.$v.answer_value.$invalid || this.$v.$invalid) return;
            try {
                if(this.answer.selected.answer !== ''){
                    await service.updateAnswer(this.answer.id, this.answer_value)
                }else {
                    await service.createAnswer(this.answer.question, this.answer_value )
                }
                this.$emit('closeModal')
                
            } catch (error) {
                console.log(error);
            } finally {
                this.$emit('reload')
            }
        }
    },
    created(){
        this.answer_value = this.answer.selected.answer;
    },
}
</script>

<style scoped>
    .modal-main-container::v-deep > .modal-main {
        width: 600px !important;
    }
    .form-group > textarea {
        border-radius: 10px;
        border-color: var(--gray-100);
        resize: none;
    }
    .form-group > .textarea-invalid {
        border-color: var(--main-red);
    }
    @media (min-width : 992px) {
        .modal-main-container::v-deep > .modal-main {
            width: 600px !important;
        }
    }
</style>
