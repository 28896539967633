<template>
    <main-modal @closeModal="$emit('closeModal')">
        <form @submit.prevent="send">
            <div class="form-group">
                <label for="review" class="title-15">{{$t('product.questions')}} <span class="color-dark-gray-200 weight-500">(300 {{$t('product.characters')}} max)</span></label>
                <textarea class="form-control" id="review" rows="5" :placeholder="$t('product.write_answer')" maxlength="300" v-model="question_value" :class="{ 'textarea-invalid': $v.question_value.$error }"></textarea>
            </div>
            <div class="d-flex mt-4">
                <second-button id="cancel" class="ms-auto me-3" :title="$t('auth.cancel')" type="button" @click="$emit('closeModal')" />
                <main-button title="home.contact.send" id="send" class="" icon>
                    <icon-send class="me-3" :size="15"/>
                </main-button>
            </div>
        </form>
    </main-modal>
</template>

<script>
import { required, maxLength} from 'vuelidate/lib/validators'
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import MainModal from '../../../../common/components/modals/MainModal.vue'
import IconSend from '../../../../common/svg/iconSend.vue'
import { ProductService } from '@/modules/products/services/product-service.js'
import SecondButton from '../../../../common/components/buttons/SecondButton.vue'
const service = new ProductService()
export default {
    name: "FormQuestion",
    data() {
        return {
            question_value: '',
            questionOriginal: '',
        }
    },
    components: {
        MainModal,
        MainButton,
        IconSend,
        SecondButton,
    },
    props: {
        question: {
            type: Object,
            default: null
        }
    },
    validations: {
        question_value: {
            required,
            maxLength: maxLength(300)
        },
    },
    methods: {
        async send(){
            try {
                this.cleanQuestion();
                if (this.questionOriginal === this.question_value) {this.$emit('closeModal');return;} 
                await service.updateQuestion(this.question.id, this.question_value)
                
                let notify = { title: this.$t('product.updated_question_title'), text: this.$t('product.updated_question_text'), type: 'success', show: true}
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);

                this.$emit('closeModal')
                this.$emit('reload')
            } catch (error) {
                console.log(error);
            }
        },
        cleanQuestion(){
            let notAllowed = ['?','¿','!']
            notAllowed.map(c => {this.question_value = this.question_value.replaceAll(c, '')})
        }
    },
    created(){
        this.question_value = this.question.selected;
        this.questionOriginal = this.question.selected;
    }
}
</script>
<style scoped>
    .form-group > textarea {
        border-radius: 10px;
        border-color: var(--gray-100);
        resize: none;
    }
</style>
