<template>
    <icon-base :width="size + 'px'" :fill="color" :viewBox="'0 0 200 200'">
        <g>
            <path class="st0" d="M143.8,149.7c4.7,3.1,7.4,6.7,7.4,10.6c0,11.4-22.9,20.6-51.2,20.6s-51.2-9.2-51.2-20.6   c0-3.9,2.7-7.5,7.4-10.6c-14,5.2-22.9,12.8-22.9,21.3c0,15.6,29.9,28.2,66.8,28.2c36.9,0,66.8-12.6,66.8-28.2   C166.8,162.4,157.8,154.8,143.8,149.7z"/>
            <path class="st0" d="M100.1,156c19.8-16.5,37.3-34.5,49.6-57c5.2-9.4,8.8-19.3,10.2-30c3.4-27.3-11.6-53.4-37-63.7   C97.5-5,68.3,2.9,52,25c-14.4,19.4-15.6,40.6-6.7,62.5C56.6,115.6,77.1,136.5,100.1,156z M100.4,32.5c15.8,0.1,28.5,13.1,28.4,29   c-0.1,15.6-13.1,28.4-28.8,28.3c-15.8,0-28.7-13-28.6-28.8C71.5,45.2,84.5,32.4,100.4,32.5z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconAddresses',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
