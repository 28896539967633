<template>
    <nav class="pagination d-flex align-items-center justify-content-end">
        <a href=# @click.prevent="setPage(current - 1)" class="me-3" :class="{'disabled': current === 1 }">
            <icon-arrow :size="14" color="var(--gray-1001)" style="transform: rotate(180deg);"/>
        </a>
        <ul>
            <li v-show="page.content != total" v-for="page in pages" @click="setPage(page.content)" :key="page.content" :class="{'current': page.content === current, 'dots-content': page.content === 0}" :style="{'visibility': page.content === 0 ? 'hidden':'visible'}">{{page.content}}</li>
            <li v-if="current === total" class="current" @click="setPage(total)" >{{total}}</li>
            <li v-if="total > 3 && current != total && current < total - 2" class="not-clickable">...</li>
            <li v-if="current < total" @click="setPage(total)">{{total}}</li>
        </ul>
        <a class="ms-3" :class="{'disabled': current === total}" @click.prevent="setPage(current + 1)">
            <icon-arrow :size="14" color="var(--gray-1001)"/>
        </a>
    </nav>
</template>

<script>
import iconArrow from '../../svg/iconArrow.vue'
export default {
    components: { iconArrow },
    name: "MainPagination",
    props: {
        total: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
            required: true,
            default: 1
        },
        visiblePage: {
            type: Number,
            default: 3
        },
        disableScroll: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        pages() {
            let items = {}
            if (this.total <= this.visiblePage) {
                for (let index = 0; index < this.total; index++) {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.current - 1)
                    }
                    items[index] = page
                }
            }else {
                const halfPageRange = Math.floor(this.visiblePage / 2)
                let setPageItem = index => {
                    let page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.current - 1)
                    }
                    items[index] = page
                }
                setPageItem(0);
                let selectedRangeLow = 0;
                if (this.current - halfPageRange > 0) {
                    selectedRangeLow = this.current - 1 - halfPageRange;
                }
                let selectedRangeHigh = selectedRangeLow + this.visiblePage - 1;
                if (selectedRangeHigh >= this.total) {
                    selectedRangeHigh = this.total - 1;
                    selectedRangeLow = selectedRangeHigh - this.visiblePage + 1;
                }
                
                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.total - 1; i++) {
                    setPageItem(i); 
                }
            }
            //in case that we surpass the current amount of visible pages (3)
            if (this.current >= 4) {
                let first_element = items['0']; // We will store the first element of the array.
                delete items['0']; // We will remove it.

                let items_2;                        // We will add a new type of element in between the first one, with content: 0
                items_2 = {'0': first_element, '1': {index: 1, content: 0, selected: false}, ...items}; //, This will be the 3 dots to be later added on its pseudo element
                return items_2;
            }else {
                return items
            }
        }

    },
    methods: {
        setPage(page){
            if (page != 0){
                this.$emit("setPage", page);
                if(!this.disableScroll) {
                    window.scroll(0, 0);
                }
            }
        }
    }
}
</script>

<style scoped>
main-pagination
    .dots-content {
        position: relative;
        visibility: hidden !important; 
        cursor: default !important;
    }
    .dots-content:after {
        content: '...';
        position: relative;
        visibility: visible;
        left: -4px;
        color: var(--gray-1001);
    }
    .pagination > ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
    }
    .pagination > ul > li {
        padding: 4px 10px;
        color: var(--gray-1001);
        font-weight: bold;
        cursor: pointer;
    } 
    .pagination > ul > .current, .test-btn {
        background: var(--main-red);
        color: white !important;
        border-radius: 5px;
    }
    .pagination > ul > li:hover {
        color: var(--dark-gray-200);
    }
    .pagination > ul > li.not-clickable:hover {
        color: var(--gray-1001);
        cursor: default;
    }
    a:hover svg{
        fill: var(--main-navy) !important;
    }
    a.disabled:hover svg{
        fill: var(--gray-1001) !important;
    }
    a.disabled {
        pointer-events: none;    
        cursor: no-drop;
    }
</style>
