<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" class="icon-cart" xml:space="preserve" :width="size + 'px'" :fill="color">
        <g>
            <path d="M98.36,27.701c-1.178-1.587-2.992-2.496-4.972-2.496H25.279l-2.591-9.025
                c-1.232-4.418-5.315-7.505-9.922-7.505H2.477c-1.141,0-2.067,0.926-2.067,2.066c0,1.14,0.926,2.066,2.067,2.066h10.29
                c2.764,0,5.206,1.843,5.95,4.496l13.137,45.855c1.232,4.418,5.315,7.505,9.924,7.505h38.858c4.439,0,8.369-2.827,9.798-7.108
                l8.89-30.374C99.892,31.288,99.542,29.288,98.36,27.701z"/>
            <path d="M45.869,74.795c-4.559,0-8.266,3.707-8.266,8.265s3.707,8.265,8.266,8.265c4.557,0,8.264-3.707,8.264-8.265
                S50.426,74.795,45.869,74.795z"/>
            <path d="M74.797,74.795c-4.559,0-8.266,3.707-8.266,8.265s3.707,8.265,8.266,8.265c4.557,0,8.264-3.707,8.264-8.265
                S79.354,74.795,74.797,74.795z"/>
        </g>
</svg>
</template>
<script>
export default {
    name: 'iconCart',
    components: {
        // iconBase: () => import('@/common/components/iconBase.vue')
    },
    props:{
        size: {
            type:Number,
            default: 20,
            },
        color:{
            type: String,
            default: ''
        }
    }
}
</script>
<style scoped>
    .st0{fill:#212E3D;}
</style>
