<template>
    <div class="row flex-wrap align-content-center align-items-center">
        <div class="d-flex justify-items-end col-12 col-sm-7 px-0"> 
            <div class="input-group pe-xl-0 mb-sm-0">    
                <input v-model="value" class="form-control" type="text" :placeholder="$t('product.search_by_keywords')" aria-label="Buscar por palabras claves">
                <div class="input-group-append">
                    <span class="input-group-text" id="search">
                        <icon-search :color="'var(--dark-gray-200)'"/>
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-sm-end align-items-center col-12 col-sm-5 mt-2 mt-sm-0 px-0">
            <span class="d-none d-xxl-inline me-3">{{$t('product.search_by')}}</span>
            <main-select :selectedOption="selectedOption" @clickHandler="(value) => $emit('filterBy', value)" summaryRef="search" :options="filterOptions" style="padding: 8px;"/>
        </div>
    </div>
</template>

<script>
import IconSearch from '@/common/svg/iconSearch.vue'
import MainSelect from '@/common/components/selects/MainSelect.vue'

export default {
    name: 'FilterSocials',
    components: {
        IconSearch,
        MainSelect,
    },
    props: {
        filterOptions: {
            type: Array,
        },
        selectedOption: {
            type: Object,
        }
    },
    data(){
        return {
            value: '',
        }
    },
    watch: {
        value: function (value){
            this.$emit('setKeyword', value)
        },
    },
}
</script>

<style scoped>
    .select-container {
        min-width: 180px;
        border-radius: 6px;
    }
    .input-group, .input-group-text {
        height: 40px;
    }
    .input-group-text {
        background: transparent;
        border-radius: 0px 8px 8px 0px;
        border-left: none;
        border-color: var(--gray-100);
    }
    .input-group-text svg {
        transition: var(--transition-1);
    }
    .form-control {
        border-radius: 8px;
        border-color: var(--gray-100);
        border-right: none;
    }
    .form-control:focus {
        border-color: var(--gray-100);
    }
    .form-control::placeholder {
        color: var(--gray-1001);
    }

    @media (min-width : 1200px) {
        .input-group {
            max-width: 380px;
        }
    }
    /* weird fix */
    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: -1px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
</style>
