<template>
  <main-modal :title="''" @closeModal="$emit('closeModal')" class="select-store" :overflowVisible="true">
    <h4 class="title-20 weight-700 mb-3">{{$t("branch.select_store")}}</h4>
    <p style="line-height: 18px;max-width:520px;" class="d-flex subtitle-15">
        {{ $t("branch.please_select_store") }}.
    </p>
    <h6 class="mt-4 mb-2">{{$t("branch.branch")}}</h6>
    <main-select :options="filterOptions" :selectedOption="selectedOption" summaryRef="select-branch" @clickHandler="setBranch"/>
    <div class="d-flex title-14 ml-2 mt-2 subtitle-14 color-dark-gray-300 px-2 text-capitalize" v-if="branchSelected">
      <icon-addresses class="me-2 fill-main-red"/>
      {{ branchSelected.city_name + ', ' + branchSelected.address | textFormat}}
    </div>
    <div class="p-0 mt-3 d-flex justify-content-end">
        <main-button title="branch.close" @click="$emit('closeModal')" style="min-width: 100px;"/>
    </div>
  </main-modal>
</template>

<script>
import { mapGetters } from "vuex"
import MainModal from '../../../common/components/modals/MainModal'
import MainSelect from '../../../common/components/selects/MainSelect.vue'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import IconAddresses from '../../layout/assets/svg/iconAddresses.vue'

export default {
    name: "SelectBranch",
    components: {
        MainModal,
        MainSelect,
        MainButton,
        IconAddresses
    },
    computed: {
      ...mapGetters({
            addressSelected: "account/addressSelected",
            branches: "branches/branches",
            branchSelected: "branches/branchSelected",
            isLogged: "auth/isLogged",
            userData: "auth/userData",
            getAddresses: "account/getAddresses",

        }),
        selectedOption(){
          const newObject = {
            value: this.branchSelected.codplace,
            title: this.branchSelected.description

          };
          return newObject
        },
        filterOptions(){
          const newObject = [];
          const options = this.branches;
          for(let option of options) {
              newObject.push( {
                  value: option.codplace,
                  title: this.$options.filters.textFormat(option.description)
              });
          }
          return newObject
        },
    },
    methods: {
    async setBranch(branch){
      try{
          if (!branch) {
            this.showError = true
            return
          } 
            let oldBranch = this.branchSelected.codplace
            await this.$store.commit('branches/SET_BRANCH', branch.value);
              if(oldBranch != branch.value){
                this.$store.commit("cart/CLEAR_CART")
                this.$store.dispatch('cart/getCartByBranch', {codplace:branch.value}); 
              }
            if (this.isLogged){
              await this.$store.dispatch('account/addressWithLimitAvaliable', {addresses : this.addresses, options : {calculate_prices: 0}})
            }
            let notify = {
              title: 'branch.notification.branch_changed_title',
              text: 'branch.notification.branch_changed_text',
              type: 'success',
              show: true,
            }
            this.$store.commit("common/SET_SHORT_NOTIFY", notify)
      }catch (e) {
          console.error(e)
          // this.loading = false
      }finally{
          // this.loading = false
      }
    },
    },
}
</script>

<style scoped>
  .select-store {
    text-align: initial;
  }
  .select-store::v-deep .modal-main {
    width: 600px !important;
  }
  #select-branch {
    padding: 6px 15px;
  }
  .select-container::v-deep > ul > li, 
  .select-container::v-deep > div{
    text-transform: capitalize;
  }
</style>